import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";
import { setNewPage } from '../../services/routerProcess';


export const NewPageForm = ({props}) => {
    const { t } = useTranslation();
    const { openNewPage, setOpenNewPage } = props;

    const handleClose = () => {
        setOpenNewPage(false);
    }
    const handleSave = (e) => {
        e.preventDefault();
        setNewPage({page_name: e.target.newPageName.value})
        setOpenNewPage(false);
    }
    return (
        <Dialog open={openNewPage} onClose={handleClose} component="form" onSubmit={handleSave} fullWidth maxWidth="sm">
            <DialogTitle>{t('appRights.newPage')}</DialogTitle>
            <DialogContent>
                <TextField 
                autoFocus
                margin="dense"
                id="newPageName"
                name="newPageName"
                label={t('appRights.pageNameField')}
                type="text"
                fullWidth
                variant="outlined"
                />
            </DialogContent>
            
            <DialogActions>
                <Button onClick={handleClose}>{t('cancel')}</Button>
                <Button variant="contained" type="submit">{t('saveBtn')}</Button>
            </DialogActions>
            
        </Dialog>
    )
}