import { useTheme } from '@emotion/react';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'; /**
 * Used on datas list pages like "StaffList" (/stafflist) to display the lists of datas
 * A specific doc is available for this component on Confluence :
 * https://goldenpalace.atlassian.net/wiki/spaces/SRL/pages/edit-v2/789807108?draftShareId=6d511d42-fe1d-46ff-bef2-0a8c9705fea3&inEditorTemplatesPanel=auto_closed
 */
import { Typography } from '@mui/material';
import {
	DataGrid,
	GridCellModes,
	GridToolbar,
	enUS,
	frFR,
	nlNL
} from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
 

export default function DatasList({
	completeList,
	columns,
	linkForNavigation = '',
	idList = '',
	redirectOn = 'none',
	editableCell = '',
	linkToId = false,
	copyCell = '',
	visibilityColumns = {},
	displaySearchBar = true,
	legend =''
}) {
	const { i18n, t } = useTranslation();
	const [tableData, setTableData] = useState(completeList);
	const navigate = useNavigate();
	const theme = useTheme()
	const locale =
		i18n.resolvedLanguage === 'fr'
			? frFR
			: i18n.resolvedLanguage === 'nl'
			? nlNL
			: enUS;

	useEffect(() => {
		setTableData(completeList);
	}, [completeList, setTableData]);
	// Edit cell management (From Mui-Doc)
	const [cellModesModel, setCellModesModel] = useState({});

	const handleCellClick = useCallback(
		(params, event) => {
			const clickedCell = params.field;
			const editable = params.isEditable;
			const redirect = redirectOn !== 'none' && redirectOn !== '';
			const isRedirectCell = redirectOn === clickedCell;
			var isAnArrayRedirect;
			const copyAllowed = copyCell !== '' && copyCell.length > 0;
			if (editable) {
				// Ignore portal
				if (!event.currentTarget.contains(event.target)) {
					return;
				}

				setCellModesModel((prevModel) => {
					return {
						// Revert the mode of the other cells from other rows
						...Object.keys(prevModel).reduce(
							(acc, id) => ({
								...acc,
								[id]: Object.keys(prevModel[id]).reduce(
									(acc2, field) => ({
										...acc2,
										[field]: { mode: GridCellModes.View }
									}),
									{}
								)
							}),
							{}
						),
						[params.id]: {
							// Revert the mode of other cells in the same row
							...Object.keys(prevModel[params.id] || {}).reduce(
								(acc, field) => ({
									...acc,
									[field]: { mode: GridCellModes.View }
								}),
								{}
							),
							[params.field]: { mode: GridCellModes.Edit }
						}
					};
				});
			} else if (copyAllowed && ( clickedCell === copyCell || copyCell.includes(clickedCell))) {
				const value = params.row?.[clickedCell];
				if(value !== null && value.length > 0){
					navigator.clipboard.writeText(value);
					toast(t('copied') + value);
				}
			} else if (redirect) {
				const link = linkToId
					? linkForNavigation + params.id
					: linkForNavigation;
				if (typeof redirectOn === 'object') {
					redirectOn.forEach((cell) => {
						if (cell === clickedCell) {
							isAnArrayRedirect = cell;
						}
						return isAnArrayRedirect;
					});
				}
				if (redirectOn === 'row' || isAnArrayRedirect || isRedirectCell) {
					navigate(link);
				} else {
					return;
				}
			} else {
				return;
			}
		},
		[copyCell, linkForNavigation, linkToId, navigate, redirectOn, t]
	);

	const handleCellModesModelChange = useCallback((newModel) => {
		setCellModesModel(newModel);
	}, []);

	columns.forEach((column) => {
		if(column.type === "integer") {
			column.sortComparator = (v1, v2) => parseInt(v1) - parseInt(v2)
		}
	})
	// Define all the options of the table
	const dataGridOptions = {
		localeText: locale.components.MuiDataGrid.defaultProps.localeText,
		rows: tableData,
		columns: columns,
		columnsVisibilityModel: visibilityColumns,
		initialState: {
			pagination: {
				paginationModel: { pageSize: 25 }
			}
		},
		pageSizeOptions: [10, 25, 50, 100],
		slots:{ toolbar: GridToolbar},
		slotProps: {
			toolbar: {
				showQuickFilter: displaySearchBar,
				printOptions: {
					disableToolbarButton: true,
				}
			},
		},
		disableRowSelectionOnClick: true,
		disableSelectionOnClick: true,
		onCellClick: handleCellClick,
		cellModesModel: cellModesModel,
		onCellModesModelChange: handleCellModesModelChange,
	};

	// Manage the click on the row or cell : Redirect or edit
	const isId =
		tableData.length && Object.keys(tableData[0]).find((key) => key === 'id')
			? true
			: false;
	if (!isId) {
		dataGridOptions.getRowId = (row) => row?.[idList];
	}

	return (
	<>
	{legend !== "" && 
		<Typography variant="caption" component="p" sx={{width:"100%", textAlign:"center",mt:4,mb:4,display:"flex", alignItems:'center',justifyContent:'center',fontWeight:"bold"}}>
			<TipsAndUpdatesIcon sx={{mr:1,color:theme.palette.secondary.light}}/>
			{legend}
		</Typography>
	}
	<DataGrid {...dataGridOptions} />
	</>
	)
	
}

