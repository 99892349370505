// import { useMsal } from "@azure/msal-react";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { GlobalContext } from "../context/Global";
// import { UserContext } from "../context/UserContext";

// In case of project where there is not at all the AD Auth, we can just delete the one coming from "UserProcessAD"
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";

/**
 * Logout button, displayed in the top right corner of the screen.
 * TODO: Check if DB logout is working. It doesn't use dB but isAuthDB
*/
export const SignOutButton = () => {
    const { mobile } = useContext(GlobalContext);
    const navigate = useNavigate()
    const { t } = useTranslation();
    /**
     * Function to auto logout the user after 3 minutes on prod, but more on local.
     * The goal is to avoid any use of someone not granted in the app with perms 
    */
    const timeout = 6000_00
    const promptBeforeIdle = 60_000
    // Auto logout 
    const [ , setIdleState ] = useState('active');
    const [ remaining, setRemaining ] = useState(timeout)
    const [ openLogoutDialog, setOpenLogoutDialog]  = useState(false);

    const onIdle = () => {setIdleState('idle');navigate('/logout')}
    const onActive = () => {setIdleState('active');setOpenLogoutDialog(false);}
    const onPrompt = () => {setIdleState('Prompted');setOpenLogoutDialog(true);}
    
    const { getRemainingTime, activate } = useIdleTimer({
      onIdle,
      onActive,
      onPrompt,
      timeout,
      promptBeforeIdle,
      throttle: 500,
    })
    useEffect(() => {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 1000)
      
      return () => {
        clearInterval(interval)
      }
    })

    const handleStillHere = () => {
      activate()
      setOpenLogoutDialog(false)
    }

     return (
      <>
        <Button 
        variant="contained"
        className="signoutButton" 
        color="secondary"
        onClick={() => navigate('/logout')}
        // onClick={() => handleSignOut()}
        >
          {mobile? <LogoutIcon /> : t('header.signoutButton')}
        </Button>
        <Dialog open={openLogoutDialog} onClose={onActive} className="autoLogoutDialog">
          <DialogTitle sx={{width:"100%", mt:2}}>
            {t('login.autologoutMessage', {remaining: remaining})}
          </DialogTitle>
          <DialogContent sx={{display:"flex", flexWrap:"wrap", justifyContent:"space-between", m:2}}>
            <Button
              variant="outlined"
              sx={{width:"auto"}}
              onClick={handleStillHere}
              >{t('header.stayConnected')}
            </Button>
            <Button
              variant="contained"
              sx={{width:"auto"}}
              onClick={() => navigate('/logout')}
              >{t('header.signoutButton')}
            </Button>
          </DialogContent>
          </Dialog>
      </>

    )
}