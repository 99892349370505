import { Button, Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NewRoomForm } from "../components/forms/NewRoom";
import DatasList from "../components/misc/DatasList";
import { GlobalContext } from "../context/Global";
import { UserContext } from "../context/UserContext";
import { getOperators, getRoomsList, getTypeRoomsList, getZoneRoomsList, getZoneTechList } from "../services/roomsProcess";

export default function RoomsList() {
    const { t } = useTranslation();
    const { isAdmin } = useContext(UserContext)
    const { mobile } = useContext(GlobalContext)
    const [ allRooms, setAllRooms ] = useState([]);
    const [ zoneRoomsList, setZoneRoomsList ] = useState([]);
    const [ operatorsList, setOperatorsList ] = useState([]);
    const [ zoneTechList, setZoneTechList ] = useState([]); 
    const [ typeRoomList, setTypeRoomList ] = useState([]);
    const [ openNewRoom, setOpenNewRoom ] = useState(false)

    useEffect(() => {
        getZoneRoomsList(setZoneRoomsList);
        getTypeRoomsList(setTypeRoomList);
        getZoneTechList(setZoneTechList);
        getOperators(setOperatorsList);
        getRoomsList(setAllRooms);
    }, [])

    const operatorsListNames = operatorsList.map((operator) => operator.name);
    const typeRoomsNames = typeRoomList.map((type) => type.designation);
    const zoneRoomsNames = zoneRoomsList.map((zone) => zone.nom);
    const zoneTechNames = zoneTechList.map((zone) => zone.nom);

    const roomsListWithValuesNames = allRooms.map(room => {
        return {
            ...room,
            operator: operatorsList.filter((operator) => operator.uuid === room.operator)[0]?.name,
            typeSalle: typeRoomList.filter((type) => type.id === room.typeSalle)[0]?.designation,
            zoneSalle: zoneRoomsList.filter((zone) => zone.id === room.zoneSalle)[0]?.nom,
            zoneTech: zoneTechList.filter((zone) => zone.id === room.zoneTech)[0]?.nom
        }
    })

    const columns = [
        {field: 'id', headerName: 'Id', width:50, type: 'integer'},
        {field: 'name', headerName: 'Name', width:mobile ? 150 : 200, type: 'string'},
        {field: 'hall', headerName: 'Hall', width:mobile ? 150 : 200, type: 'string'},
        {field: 'isOn', headerName: 'IsOn', width:70, type: 'integer'},
        {field: 'operator', headerName: 'Operator', width:150, type: 'singleSelect', valueOptions:operatorsListNames},
        {field: 'typeSalle', headerName: 'Type', width:100, type: 'singleSelect', valueOptions:typeRoomsNames},
        {field: 'zoneSalle', headerName: 'Zone salle', width:100, type: 'singleSelect', valueOptions:zoneRoomsNames},
        {field: 'zoneTech', headerName: 'Zone Tech', width:100, type: 'singleSelect', valueOptions:zoneTechNames},
        {field: 'prefixBank', headerName: 'Prefix Bank', width:100, type: 'string'},
        {field: 'hid', headerName: 'Hid', width:50, type: 'integer'},
        {field: 'uuid', headerName: 'Uuid', width:320, type: 'string'},
    ]

    if (isAdmin) {
        columns.push({
            field: 'system',
            headerName: 'System',
            width:100, 
            type: 'integer'
        })
    }
    return (
        <Container>
            <Typography variant="h2" sx={{ display: "flex", justifyContent: "space-between"}}>
                {t('menu.roomsList')}
                <Button variant="contained" onClick={() => setOpenNewRoom(true)}>{t('rooms.newRoom')}</Button>
            </Typography>
            <NewRoomForm props={{openNewRoom, setOpenNewRoom, operatorsList, typeRoomList, zoneRoomsList, zoneTechList}}></NewRoomForm>
            <DatasList 
                completeList={roomsListWithValuesNames}
                columns={columns}
                linkForNavigation={null}
            />
        </Container>
    )
}