import { useMsal } from "@azure/msal-react";
import Button from '@mui/material/Button';
import React from "react";
import { useTranslation } from "react-i18next";
import { loginRequest } from "../authConfig";

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 * used for MSAL Login
 */


export const SignInButton = () => {
    const { t } = useTranslation();
    const { instance } = useMsal();    
    
    const handleLogin = (loginType) => {
        if (loginType === "redirect") {
            if(localStorage.getItem("loggedOut")){
                localStorage.removeItem('loggedOut');
            }
            
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
    }
    return (
        <Button variant="contained" color="secondary" className="ml-auto" onClick={() => handleLogin("redirect")} sx={{margin:"auto",width:"fit-content",mt:2}}>{t('login.connectButton')}</Button>
    );
}