import { msalConfig } from "../authConfig";

/**
 * Description : all the requests to Msal (Microsoft Authentication Service)
 */

// Sign out function
export const HandleSignOutAD = (instance, datasFromMS, HandleSignOutDB, setIsAuthDB, navigate) => {
  console.log('start signout from microsoft')
    const homeAccountId = (datasFromMS && datasFromMS.homeAccountId)

    const logoutRequest = {
      account: instance.getAccountByHomeId(homeAccountId),
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
    };
    console.log("logout parameters : "+logoutRequest)
    instance.logoutRedirect(logoutRequest).catch((err) => console.log("error ms logout = " + err));
    console.log('MS Instance =', instance)
    HandleSignOutDB(setIsAuthDB, navigate);
}

// Retrieve all the current user informations
export const HandleUserAD = ({ instance, accounts, lang }) => {
  console.log("HandleUserAD")
    const callMsGraph = (token) => {
      const headers = new Headers();
          headers.append("Authorization", `Bearer ${token}`);
          const fields = [
              "accountEnabled",
              "companyName",
              "givenName", 
              "id",
              "mail", 
              "surname", 
              // "createdDateTime",
              "department",
              // "displayName", 
              "jobTitle", 
              // "mobilePhone",
              // "officeLocation", 
              // "preferredLanguage",
          ];
          const msGraphUser = fetch(`https://graph.microsoft.com/v1.0/me?$select=${fields.join()}`, {
              method: "GET",
              headers,
          }).then((response) => response.json())
          return msGraphUser
    };
  
    return new Promise((resolve, reject) => {
      instance
      .acquireTokenSilent({
        scopes: ["openid", "profile", "user.read"],
        account: accounts[0],
        authority: `https://login.microsoftonline.com/${msalConfig.auth.tenantId}`,
      })
      .then((response) => {  
        callMsGraph(response.accessToken).then((data) => (
            {
                homeAccountId: response.account.homeAccountId,
                accountEnabled: data.accountEnabled,
                companyName: data.companyName,
                prenom: data.givenName,
                uuid_AD: data.id,
                mail: data.mail,
                nom: data.surname,
                lang: lang
                // createdDateTime: data.createdDateTime,
                // department: data.department,
                // displayName: data.displayName,
                // jobTitle: data.jobTitle,
                // mobilePhone: data.mobilePhone,
                // officeLocatio: data.officeLocation,
                })).then((userdatas) => resolve(userdatas)).then((datas) => datas).catch((error) => {reject(error)})
            });
    })
    // return handleUser();
};
// TODO : See if we can use this to load a user list from AD
// export const getUserListAD = ({ instance, accounts }) => {
//   const callMsGraph = (token) => {
//     const headers = new Headers();
//         headers.append("Authorization", `Bearer ${token}`);
//         const fields = [
//             "companyName",
//             "givenName", 
//             "id",
//             "mail", 
//             "surname", 
//             // "createdDateTime",
//             // "department",
//             // "displayName", 
//             // "jobTitle", 
//             // "mobilePhone",
//             // "officeLocation", 
//             // "preferredLanguage",
//         ];
//         const msGraphUser = fetch(`https://graph.microsoft.com/v1.0/me?$select=${fields.join()}`, {
//             method: "GET",
//             headers,
//         }).then((response) => response.json())
  
//         return msGraphUser
//   };

//   return new Promise((resolve, reject) => {
//     instance
//     .acquireTokenSilent({
//       scopes: ["openid", "profile", "user.read"],
//       account: accounts[0],
//       authority: `https://login.microsoftonline.com/${msalConfig.auth.tenantId}`,
//     })
//     .then((response) => {  
//       callMsGraph(response.accessToken).then((data) => (
//           {
//               companyName: data.companyName,
//               prenom: data.givenName,
//               uuid_AD: data.id,
//               mail: data.mail,
//               nom: data.surname,
//               })).then((userdatas) => resolve(userdatas)).catch((error) => {reject(error)})
//           });
//   })
//   // return handleUser();
// };