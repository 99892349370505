import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { Button, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
// import Grid from '@mui/material/Grid';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import Checkbox from '@mui/material/Checkbox';
// import Button from '@mui/material/Button';
// import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../../context/Global';

/*
  Warning : 
  This component is used in some place of the site whenever we need to have a "switch list". It NEED to have as props: 
  1. completeList : The complete list of datas to display (including those who are already selected)
  2. savedDatas : The list of the datas already saved
  3. submitFct : The function which will submit all the datas (ex: handleUserRooms)
  4. forwhat : The data which will allow the DB to recognize the thing to modify (uuid, user mail, roomId, ...)

  Somes parts are commented because they are for transfer ALL the list. We don't need them for now but maybe we could need them in the future.

  All the documentations for this component can be found here : https://mui.com/material-ui/react-transfer-list/

  TODO : 
  Comment the differents parts of it
*/

// not() and intersection() are Mui TransferList functions and shouldn't be deleted.
function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}


export default function TransferList({completeList, savedDatas, submitFct, forwhat}) {
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const { t } = useTranslation();
  const { mobile } = useContext(GlobalContext)

  

  useEffect(() => {
    // These 2 consts will sort the both lists by Alphabetical order
      const sortedCompleteList = completeList.sort(function(a, b) {
        return a.text.localeCompare(b.text)
      })
      const sortedSavedList = savedDatas.sort(function(a, b) {
        return a.text.localeCompare(b.text)
    })
    setLeft(sortedCompleteList);
    setRight(sortedSavedList)
  },[completeList, savedDatas])

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  // Function to check the list items
  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };


   const handleAllRight = () => {
     setRight(right.concat(left));
     setLeft([]);
   };

   const handleAllLeft = () => {
     setLeft(left.concat(right));
     setRight([]);
   };


// These 2 functions are used to update the lists
  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  
// Lists display
  const customList = (items) => (
  
    <Paper sx={{width:'100%', height:'300px', overflow: 'auto' }}>
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.id}-label`;
          return (
            <ListItem
              key={value.id}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.text} />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
  return (
    <Grid container spacing={2} sx={{mt:1}} direction="row" align-items="center" justifyContent="space-between">
      <Grid item sx={{width:mobile ? '100%' : '40%'}}>
        {customList(left.sort(function(a, b) {
          return a.text.localeCompare(b.text)
        }))}
      </Grid>
      <Grid item sx={{width:mobile ? '100%' : 'auto'}}>
        <Grid container sx={{height:mobile ? 'auto' : '100%', width:'auto',flexDirection:"column",justifyContent:"center"}}>
          <Button
            sx={{ my: 0.5, width:'5%' }}
            variant="outlined"
            size="small"
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            {mobile ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
          </Button>
          <Button
            sx={{ my: 0.5}}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            {mobile ? <KeyboardArrowUpRoundedIcon /> : <KeyboardArrowLeftRoundedIcon />}
          </Button>
          <Button
            sx={{ my: 0.5, width:'5%' }}
            variant="outlined"
            size="small"
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item sx={{width:mobile ? '100%' : '40%'}}>
        {customList(right.sort(function(a, b) {
          return a.text.localeCompare(b.text)
        }))}
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Button variant="contained" onClick={() => {submitFct(forwhat, right)}} disabled={(right.length > 0 ? false : true)}>{t('syncBtn')}</Button>
      </Grid>
    </Grid>
  );
}