import WarningIcon from '@mui/icons-material/Warning';
import { Button, Container, FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";

/**
 * This page is only available for dev (or should be) to test the differents call API methods
 * Link : /devTool
 * Available for : admins only (for now, should be only for developers)
 * Description : This page is used for testing the call API 
 */

const headers = new Headers({
  'User-Agent': 'Client Synchro',
  'X-Custom-User-Agent': 'Client Synchro v0.1',
  'Content-Type': 'application/json;charset=utf-8',
  'Authorization': 'Basic Q1NMb3VyZDpmZTVjMmVhOTMxODk1MTJlMmY4NDcyMjg5MThlMjcyOWU4OTFmZDk2ZmI5MGI3YzBlMDRmYmFiYmQ3OWZhODMz',
  "Access-User": localStorage.getItem("mail"),
})
// Function to tests the API methods
const apiTest = async (method, params, setResultDebug) => {
  const requestOptions = {
    jsonrpc: "2.0",
    method: "POST",
    crossDomain: true,
    withCredentials: true,
    headers: headers,
    body: JSON.stringify({
      id: 0,
      method: `Personnel\\${method}`,
      params: params,
    }),
  };
  const data = 
    await fetch(process.env.REACT_APP_API_ENDPOINT, requestOptions)
    .then((response) => response.json(), console.log(this))
    .then(data => setResultDebug(JSON.stringify(data)))
    .catch((err) => {console.log("error API");console.error(err);});
  return data;
}
const setPredefinedparam = (value,setPredefinedValue) => {
  switch (value) {
    case "IStaff::OpenAccess":
      setPredefinedValue('[{"mail":"eugenie.jmil@goldenpalace.be"}]');
      break;
    case "IStaff::CloseAccess":
      setPredefinedValue('[{"mail":"eugenie.jmil@goldenpalace.be"}]');
      break;
    case "IEditor::Get":
      setPredefinedValue('[{"table":"roles"}]');
      break;
    case "IStaff::SetStaff":
      setPredefinedValue('[{"mail":"fake.user@goldenpalace.be", "nom": "fake", "prenom":"user", "role":"visitor"}]');
      break;
    default:
      setPredefinedValue("");
}	
}

export default function ApiTests() {
    const [ resultDebug, setResultDebug ] = useState()
    const [ predefinedValue, setPredefinedValue] = useState("")
    return(
        <Container>
            <Typography variant="h2">Api methods test page</Typography>
            <Grid container component="form" spacing={4} 
            onSubmit={(e) => {
                e.preventDefault(); 
                let method = e.target.method.value;
                let params; 
                try {params = JSON.parse(e.target.params.value);} catch (error) {params = e.target.params.value}
                // let params = JSON.parse(e.target.params.value);
                apiTest(method, params, setResultDebug);
            }}>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth sx={{marginBottom:2}}>
                        <TextField 
                        id="methodField"
                        name="method" 
                        placeholder="IStaff::GetStaffs" 
                        label="Method to test" 
                        InputProps={{
                          onBlur: (e) => {setPredefinedparam(e.target.value, setPredefinedValue)}
                        }}
                        
                        required/>
                    </FormControl>
                    <FormControl variant="outlined" fullWidth sx={{marginBottom:2}}>
                        <TextField 
                        id="paramsField"
                        name="params" 
                        label="Optional parameters" 
                        multiline 
                        value={predefinedValue}
                        onChange={(event) => setPredefinedValue(event.target.value)}
                        rows={6}
                        placeholder="[{'mail': 'eugenie.jmil@goldenpalace.be'},
                            ['id', 'nom','prenom','role','uuid','mail','lang','active','company']]"/>
                          <Typography variant="caption" component="p" sx={{display:"flex",alignItems:"center",color:"red",margin:1,wordWrap: "break-word"}} ><WarningIcon /> Don't use simple quotes for Parameters, only double quotes.</Typography>
                    </FormControl>
                    <Button variant="contained" type="submit" fullWidth>Test</Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper id="callresult" elevation={8} sx={{width:'100%',padding:2,height:'100%',wordBreak: "break-all"}}>Results : <br/>{resultDebug}</Paper>
                </Grid>
            </Grid>
        </Container>
    )
}
