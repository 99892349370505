import { Button, Checkbox, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NewGrantForm } from "../components/forms/NewGrantForm";
import { NewPageForm } from "../components/forms/NewPageForm";
import { decomposeBinaryNb } from "../functions";
import { getUserRoles } from "../services/roomsProcess";
import { getGrants, getRouterAccess, updateRouterAccess } from "../services/routerProcess";

/**
 * Link : /apprights
 * Available for : admins only
 * Description : This page allows admins to manage the router access and set who can access any pages.
 */

export default function AppRights() {
    const { t } = useTranslation();
    const [ checkedRights, setCheckedRights ] = useState([]);
    const [ pageList, setPageList ] = useState({});
    const [ allGrants, setAllGrants ] = useState([]);
    const [ openNewGrant, setOpenNewGrant ] = useState(false);
    const [ openNewPage, setOpenNewPage ] = useState(false);
    const [ rolesList, setRolesList ] = useState([]);
    useEffect(() => {
        getRouterAccess(setPageList,true);
        getGrants(setAllGrants);
        getUserRoles(setRolesList)
    },[])
    const pagesForGrants = [];
    const routerPagesForGrants = [];

    allGrants.map((grant) => {
        return !pagesForGrants.includes(grant.page) && pagesForGrants.push(grant.page)
    })
    Object.keys(pageList).forEach((page) => {
        return routerPagesForGrants.push(page);
    })
    
    // Function to change the check state of an input
    const handleToggle = (value) => () => {
        const currentIndex = checkedRights.indexOf(value);
        const newChecked = [...checkedRights];
    
        if (currentIndex === -1) {
          newChecked.push(value);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        setCheckedRights(newChecked);
      };

    // function used to exclude all the datas from roles or router that we will not change
    const inArray = (needle, haystack) => {
        var length = haystack.length;
        for(var i = 0; i < length; i++) {
            if(haystack[i] === needle) return true;
        }
        return false;
    }
    
    // This function will check any row in the table and create an array with all the pages and the roles allowed to access them.
    const savePageRights = (page_name) => {
        const rows = document.querySelectorAll(`tr[class*="row__${page_name}"]`);
        const datas = {};
        datas['page_name'] = page_name;
        const details = {};
        [...rows].map((row) => {
            const checkedBoxes = row.querySelectorAll('input[type="checkbox"]');
            if(checkedBoxes.length > 0) {
                const alias = {}
                const name = row.firstChild.innerText;
                checkedBoxes.forEach((el) => {
                    if(el.checked){alias[el.name.split('_')[1]] = 1;}  
                })
                details[name] = alias;
                datas["details"] = [details];
            }
            return datas;
        })
        updateRouterAccess(datas);
    }

    const createGrantsByPageArray = () => {
        const allLines = [];
        allGrants.sort((a, b) => {
            if( a.page < b.page) { return -1 } 
            else { return 1 }
        });

        Object.entries(pageList).forEach((page) => {
            const pageLines = {};
            allGrants.filter((grant) => {
                // Grant = {"id": "10","page": "user","flag": "264","alias": "new card","path": "1.2"}
                // Page = ["test",{"districtmanager": 0,"staffmember": 0,"tech": 0,"marketing": 0,"techlead": 0,"fieldmanager": 0,"fieldassistant": 0,"controlling": 0,"admin": 0,"rh": 0,"visiteur": 0}]
                // console.log((grant.page === page[0] || grant.page === "common"))
                if((grant.page === page[0] || grant.page === "common") && grant.alias !== "non"){
                    var line = [];
                    rolesList.forEach((role) => {
                        var rights = decomposeBinaryNb(page[1][role.name]);
                        line[role.name] = rights.find((nb) => nb === parseInt(grant.flag)) ? true : false;
                    })
                    pageLines[grant.alias] = line;
                }
                return pageLines
            })
            allLines[`${page[0]}`] = pageLines
        });
        return allLines
    }

    const handleNewPage = () => {
        setOpenNewPage(true);
    }
    const handleNewGrant = () => {
        setOpenNewGrant(true);
    }

    const listedGrantsByPage = createGrantsByPageArray();
    return (
        <Container>
            <Typography variant="h2" className="titleDbBtn">
                {t('menu.appRights')}
                <Button variant="contained" onClick={() => handleNewGrant()}>{t('appRights.newGrant')}</Button>
                <Button variant="contained" onClick={() => handleNewPage()}>{t('appRights.newPage')}</Button>
            </Typography>

            <NewGrantForm props={{openNewGrant, setOpenNewGrant, pagesForGrants, routerPagesForGrants}} />
            <NewPageForm props={{openNewPage, setOpenNewPage}} />

            <TableContainer className="tableContainerGridResp">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" key={"column_header_0"}>Right</TableCell>
                            {rolesList.map((role,index) => {
                                return !inArray(role.name, ['main_menu']) &&
                                <TableCell align="center" key={`column_header_${index+1}`} sx={{textTransform:"capitalize"}}>{role.name}</TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    
                        {Object.entries(listedGrantsByPage).map((pageName, indexRow) => {
                            const page_name = pageName[0].split('_')[0];
                            const page_id = pageName[0].split('_')[1];
                            return (
                                <TableBody key={`table_${page_name}`}>
                                    <TableRow className="tableCategoryName"key={`row_header_${page_name}`}>
                                        <TableCell colSpan={6} key={`row_name_${page_name}`}>{page_name}</TableCell>
                                        <TableCell colSpan={7} key={`row_save_${page_name}`}><Button variant="contained" size="small" sx={{ml:"auto",display:"block"}} onClick={(event) => {savePageRights(page_name)}}>{t('saveBtn')}</Button></TableCell>
                                    </TableRow>
                                    {Object.entries(pageName[1]).map((grant,indexGrant) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={`row_${indexRow}_${indexGrant}`} className={`row__${page_name}`} >
                                                <TableCell 
                                                align="center" 
                                                key={`row__${page_name}_0_${indexRow}_${indexGrant}`} 
                                                sx={{textTransform:"capitalize", fontWeight:"700"}}
                                                >
                                                    {grant[0]}</TableCell>
                                                {Object.entries(grant[1]).map((element,index) => {
                                                    return (
                                                        <TableCell align="center" key={`cell_${index+1}_${indexGrant}_${indexRow}`}>
                                                            <Checkbox 
                                                                defaultChecked={element[1]} 
                                                                name={`${page_name}_${element[0]}`}
                                                                inputProps={{ 'aria-label': `${page_name}_${page_id}_${element[0]}`}}
                                                                onClick={handleToggle(`${page_name}_${page_id}_${element[0]}`)}
                                                            /> 
                                                        </TableCell>
                                                    )
                                                })}    
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            )
                        })}                
                    
                </Table>
            </TableContainer>
            
        </Container>
    )
}