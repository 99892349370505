import { Box, Button, Card, FormHelperText, TextField, Typography } from "@mui/material";
import CardContent from '@mui/material/CardContent';
import { Container } from "@mui/system";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../context/Global";
import { UserContext } from "../context/UserContext";
import { DatasFromDB, HandleLoginDB } from "../services/userProcessDB";
import { SignInButton } from "./SignInButton";
import { SelectLang } from "./forms/SelectLang";

/** 
 * Display the good login way depending if mSAL is enabled or not : Msal redirect buttons or form with Email and password.
 * Props : 
 * msalEnabled : Based on Env variables, define if Msal is enabled or not.
 * t : Translation function.
 * setIsAuthDB : State function to set isAuthDB. While isAuthDB is true, the user is logged in.
 */

const LoginDisplayed = ({msalEnabled, t, setIsAuthDB, setUserDatas}) => {
    const [ errorLogin, setErrorLogin ] = useState(false);
    if(msalEnabled) {
        return(
        <CardContent sx={{textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"space-around"}}>
            <Typography variant="h2" component="h1">
                {t('siteName')} 
            </Typography>
            <Typography variant="p">{t('login.loginMessageMsal')}</Typography>
            <SignInButton />
        </CardContent>
        )
    } else{
        const sha1 = require('js-sha1');
        const handleLogin = (event) => {
            event.preventDefault();
            /* TODO : Add the API Call to check if user exists : 
             Exists and credentials are correct = ok, login
             Exists and password not set = display a message to redirect to Cluster to create the password in profile
             Exists and credentials are incorrect = display an error message
             Doesn't exists = display an error message to warn that only GP User can have an account and advice to use Cluster to create the user account
            */
            const userName = event.target.loginUsername.value;
            const passWord = sha1(event.target.loginPassword.value);
            setErrorLogin(HandleLoginDB({"mail":userName, "passwd":passWord}, setIsAuthDB))

            if(!errorLogin){
                localStorage.setItem('login', userName);
                setUserDatas(DatasFromDB([{"mail":userName}]));
            }
        }

        return(
        <CardContent sx={{textAlign:"center",display:"flex",flexDirection:"column",justifyContent:"space-around"}}>
            <Typography variant="h2" component="h1">
                {t('siteName')} 
            </Typography>
            <Box component="form" autoComplete="off" sx={{display:"flex",flexWrap:"wrap",boxSizing:"border-box","& .MuiTextField-root": { m: 2, width: '100%' }}} onSubmit={handleLogin}>
                <TextField 
                required
                name="loginUsername"
                id="loginUsername"
                label={t('login.username')}
                sx={{width:"49%"}}
                />
                <TextField
                required
                name="loginPassword"
                id="loginPassword"
                label={t('login.password')}
                type="password"
                autoComplete="current-password"
                sx={{width:"49%"}}
                />
                <Button type="submit" variant="contained"sx={{width:"49%",margin:"16px auto"}}>{t('login.connectButton')}</Button>
                
            </Box>
            <FormHelperText component="p" sx={{m: "auto",textAlign:"center",width:"100%",display:(errorLogin ? "block":"none")}} error={errorLogin}>{t('login.invalid')}</FormHelperText >
        </CardContent>
        )
        

    }
}

export default function Login(props) {
    const { t } = useTranslation();
    const { globalValues } = useContext(GlobalContext);
    const { setIsAuthDB, setUserDatas } = useContext(UserContext);
    return (
        <Container className="loginBox"
        sx={{}}>
            <Card sx={{display:"flex",position:"relative","& .langSelect":{position:"absolute",right:0,top:0}}}>
                <SelectLang from="login" />
                <LoginDisplayed msalEnabled={globalValues.msalEnabled} t={t} setIsAuthDB={setIsAuthDB} setUserDatas={setUserDatas} />
            </Card>
        </Container>

    );
};