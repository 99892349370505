import { useMsal } from "@azure/msal-react";
import { createContext, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { lg } from "../datas/lang";
import { pagesList } from "../datas/pagesList";
import { decomposeBinaryNb } from "../functions";
import { getGrants } from "../services/routerProcess";
import { HandleUserAD } from "../services/userProcessAD";
import { HandleLoginDB } from "../services/userProcessDB";
import { GlobalContext } from "./Global";
/**
 * Contain all the user datas, from AD if msal is enabled and from DB if not or for other informations not available on AD
 * Contain also the router 
 * Define also some check points for user (isAdmin and isRH) to define permissions
 */

const initUserAD = () => ({
  userdatas: {
    homeAccountId: "",
    accountEnabled: "",
    company: "",
    createdDateTime: "",
    department: "",
    displayName: "",
    givenName: "",
    id: "",
    jobTitle: "",
    mail: "",
    mobilePhone: "",
    officeLocation: "",
    surname: "",
    userType: "",
  },
  language: "1",
});
const initUserDB = () => ({
    id: "",
    nom: "",
    prenom: "",
    role: "visitor",
    uuid: "",
    mail: "",
    lang: 1,
    active: 0,
    // cardN: 0,
    // serial: "",
    // persN: "",
    // uuid_AD: "",
    // create_date: "",
    // update_date: "",
})
export const UserContext = createContext({});

export const UserContextProvider = ({ children }) => {
  const { globalValues, setIsAuthDB } = useContext(GlobalContext);

  const [ userDatasAD, setUserDatasAD ] = useState(initUserAD.userdatas);
  const [ userDatas, setUserDatas ] = useState((localStorage.getItem('userDatas') && localStorage.getItem('userDatas').length > 0) ? JSON.parse(localStorage.getItem("userDatas")) : initUserDB.userdatas);
  const [ allGrants, setAllGrants] = useState((localStorage.getItem('gr') && localStorage.getItem('gr').length > 0) ? JSON.parse(localStorage.getItem('gr')) : []);
  const [ router, setRouter ] = useState((localStorage.getItem('userDatas') && localStorage.getItem('userDatas').length > 0) ? JSON.parse(localStorage.getItem('userDatas')).pages : {})
  const [ completeUserAccess, setCompleteUserAccess ] = useState((localStorage.getItem('ugr') && localStorage.getItem('ugr').length > 0) ? JSON.parse(localStorage.getItem('ugr')) : {})

  const { instance, accounts } = useMsal();
  const { i18n } = useTranslation();
  const currentLang = i18n.resolvedLanguage;
  const lang = (lg.find((lang) => lang.short === currentLang)).id;

  const checkGrants = (allGrants, router, page) => {
    const right = router[page];
    const filteredGrants = []
    allGrants.filter(function(entry){
      if((entry.page === page || entry.page === "common")){
        if((entry.page >>> right).toString(2)){
          filteredGrants.push({
            page: entry.page,
            flag: entry.flag,
            alias: entry.alias,
            path: entry.path
          })
        }
      }
      return filteredGrants;
    })
    const binaryGrants = decomposeBinaryNb(right);
    const resultats = filteredGrants.filter((el) => {
      return binaryGrants.some(nb => nb === parseInt(el.flag));
    })
    return resultats;
  }

  const userAccess = () => {

    if(localStorage.getItem('userDatas')){
      const pages = JSON.parse(localStorage.getItem('userDatas')).pages;

      if(pages && Object.keys(pages).length){
        setRouter(pages)
        
        const accessArray = {};
  
        if (Object.keys(pages).length && allGrants.length && !localStorage.getItem('ugr')){
          pagesList.forEach((el) => {
            
            let newArrayInCompleteUserAccess = checkGrants(allGrants, pages, el.name);
            if(newArrayInCompleteUserAccess.length){
              accessArray[el.name] = newArrayInCompleteUserAccess;
            }
          })
          setCompleteUserAccess(accessArray);
          localStorage.setItem('ugr',JSON.stringify(accessArray));
        }
              
      }    
    }
    
  }

  const localIsAuthDB = localStorage.getItem("isAuthDB");
  useEffect(() => {
    if (globalValues.msalEnabled && accounts.length && (!globalValues.isAuthDB || globalValues.isAuthDB === "false") ) {
      HandleUserAD({ instance, accounts, lang }).then((userdatas) =>{ 
        setUserDatasAD(userdatas)
        if(
          (!localStorage.getItem('userDatas') 
          && (!localStorage.getItem('loggedOut') || localStorage.getItem('loggedOut') === true)) 
          || !sessionStorage.getItem('accessOpened')
        ){
          HandleLoginDB(userdatas, setUserDatas, setIsAuthDB, getGrants, setAllGrants);
        }
      })
    } 
    // else if () {
    //   HandleLoginDB(userDatas, setUserDatas, setIsAuthDB);
    // }
    if (!allGrants?.length && localStorage.getItem('gr')) {
      (JSON.parse(localStorage.getItem('gr')));
    }

    if (allGrants?.length) {
      userAccess();
    }
    // eslint-disable-next-line
  }, [accounts, globalValues.isAuthDB, globalValues.msalEnabled, instance, lang, setIsAuthDB, userDatas?.role, localIsAuthDB, userDatas?.pages, allGrants?.length]);

  const isAdmin = userDatas?.role === "admin";
  const isRH = userDatas?.role === "rh";
  const isTechLead = userDatas?.role === "techlead";
  return (
    <UserContext.Provider
      value={{
        userDatas,
        userDatasAD,
        setUserDatas,
        setUserDatasAD,
        setIsAuthDB,
        router,
        completeUserAccess, 
        isAdmin, 
        isRH,
        isTechLead
      }}
    >
      {children}
    </UserContext.Provider>
  );
};