import { toast } from "react-hot-toast";
const headers = new Headers({
  'User-Agent': 'Client Synchro',
  'X-Custom-User-Agent': 'Client Synchro v0.1',
  'Content-Type': 'application/json',
  'Authorization': 'Basic Q1NMb3VyZDpmZTVjMmVhOTMxODk1MTJlMmY4NDcyMjg5MThlMjcyOWU4OTFmZDk2ZmI5MGI3YzBlMDRmYmFiYmQ3OWZhODMz'
})


const fetchDatas = async (method, params = []) => {
  const requestOptions = {
    jsonrpc: '2.0',
    method: 'POST',
    headers: headers,
    body: JSON.stringify({
      id: 0,
      method: `luckyWheel\\ILuckyWheel::${method}`,
      params
    })
  };

  const data = await fetch("https://loyalty.citech.be/api/rpc.php",requestOptions)
  .then((response) => response.json())
  .then((result) => {return result.result})
  .catch((err) => {console.log(err);toast.error(err)});
  return data;
}

export const getAllConfig = (uuid, setAllConfig) => {
  const getConfigs = fetchDatas('GetConfig', [{"uuid_salle":uuid}])
  getConfigs.then((response) => {


    setAllConfig(response)
    return response;
  })
}

export const getAllResults = (uuid, setAllResults) => {
  const getResults = fetchDatas('GetResult',[{"uuid_salle":uuid}]) 
  getResults.then((response) => {
    setAllResults(response)
    return response;
  })
}

export const getAllActiveList = (uuid, setAllActive) => {
  const getActives = fetchDatas('GetActivateList',[{"uuid_salle":uuid}]);
  getActives.then(response => {
    setAllActive(response)    
    // return response;
  })
}

export const createActive = (params, refreshList) => {
  const create = fetchDatas('SetActivateList',params) 
  create.then((response) => {
    refreshList(response[0])
  })
}

export const updateActive = (params, refreshList) => {
  const update = fetchDatas('ModifActivate', params)
  update.then((response) => {
    refreshList(response[0])
  })
}
