import { Container, Typography } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { enGB, fr, nlBE } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatasList from '../components/misc/DatasList';
import { getRoomsList } from '../services/roomsProcess';
import { getAlltechInterventions } from '../services/techProcess';
export default function InterventionsTech() {

    const { t, i18n } = useTranslation();
    const [ allRooms, setAllRooms ] = useState([]);
    const [ allInterventions, setAllInterventions ] = useState([]);
    const today = new Date();
    const [ day, setDay ] = useState(`${today.getFullYear()}-${("0" + (today.getMonth() +1)).slice(-2)}-${("0" + today.getDate()).slice(-2)}`);
    useEffect(() => {
        getRoomsList(setAllRooms);
        getAlltechInterventions(day,setAllInterventions)
    },[day])

    const roomsNames = allRooms.map((room) => room.name);

    const columns = [
        {field: 'id', headerName: 'Id', width: 100, type:'integer', valueGetter: (value) => value.row.lid},
        {field: 'nom', headerName: t('tech.techName'), width: 300, type:'singleSelect', valueGetter: (value) => `${value.row.prenom} ${value.row.nom}`},
        {field: 'salle', headerName: t('tech.room'), width: 300, type:'singleSelect', valueOptions: roomsNames},
        {field: 'din', headerName: t('tech.in'), width: 200, type:'string',valueGetter: (value) => value.row.din},
        {field: 'dout', headerName: t('tech.out'), width: 200, type:'string',valueGetter: (value) => value.row.dout},
    ]
    const locale = i18n.resolvedLanguage === 'fr' ? fr : i18n.resolvedLanguage === 'nl' ? nlBE : enGB;
    const handleChangeDate = (value) => {
        const newDate = `${value.getFullYear()}-${("0" + (value.getMonth() +1)).slice(-2)}-${("0" + value.getDate()).slice(-2)}`
        setDay(newDate)
        // getAlltechInterventions(newDate,setAllInterventions)
        
    }


    return (
        <Container>
            <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                {t('menu.interventionsTech')}
                <LocalizationProvider
                    adapterLocale={locale}
                    dateAdapter={AdapterDateFns}
                >
                    <DatePicker
                        label='Date'
                        slotProps={{ textField: { variant: "standard" } }}
                        defaultValue={Date.parse(day)}
                        // renderInput={(props) => <TextField label="dateIn" {...props} />}
                        sx={{width:'auto'}}
                        onChange={(value) => handleChangeDate(value)}
                    />
                </LocalizationProvider>
            </Typography>

            <DatasList 
                completeList={allInterventions}
                columns={columns}
                linkForNavigation='/'
                idList="lid"
                displaySearchBar={false}
            />
        </Container>
    )
}
