import SaveIcon from '@mui/icons-material/Save';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, OutlinedInput } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import md5 from "md5";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/Global";
import { UserContext } from "../../context/UserContext";
import { updateUser } from "../../services/userProcessDB";
import { SelectLang } from "./SelectLang";
// Used on "Profile" (/profile) to display user datas

export default function PersonalUser() {
    const [showPassword, setShowPassword] = useState(false);
    const { t } = useTranslation();
    const { userDatas,completeUserAccess } = useContext(UserContext);
    const { mobile } = useContext(GlobalContext);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    // Show/hide password
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    // Call the api to save the password in md5
    const sendDatas = (event) => {
      event.preventDefault();
      const passWord = md5(event.target.changePswd.value);
      updateUser([{passwd:passWord},{mail:userDatas.mail}])
    }    
    const newCard = Object.values(completeUserAccess.profile).find(val => val.alias === "new card")
    return(
      <Grid item container xs={4} direction="column" alignItems="baseline" justifyContent="space-between">
        <Box 
        component="form" 
        autoComplete="off" 
        sx={{display:"flex",boxSizing:"border-box", width:"100%",justifyContent:"space-between",mb:2,"& .MuiFormControl-root": { m: 0, width: '70%' }, "& .MuiButton-root": {m:0, width:"28%"}}} onSubmit={sendDatas}>
          <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
            <InputLabel htmlFor="changePswd">{t('profile.changepswd')}</InputLabel>
            <OutlinedInput
              name="changePswd"
              id="changePswd"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label={t('profile.changepswd')}
            />
            
          </FormControl>
          <Button type="submit" variant="contained">{mobile ? <SaveIcon /> : t('saveBtn')}</Button>
        </Box>
        <SelectLang from="profile" />
        {newCard !== undefined && 
        <Button variant="contained" sx={{mb:2, width:"100%"}}>{t('staffManagement.askCard')}</Button>
      }
      </Grid>
    )
  }
