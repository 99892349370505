export const pagesList = [
    {
        name: 'user',
        level: 1.2,
        order: null,
        cat: 'users'
    },
    {
        name: 'staffList',
        level: 1,
        order: 2,
        cat: 'users'
    },
    {
        name: 'profile',
        level: 1,
        order: 1,
        cat: 'users'
    },
    {
        name: 'appRights',
        level: 1,
        order: 3,
        cat: 'admin'
    },
    {
        name: 'devTool',
        level: 1,
        order: 1000,
        cat: 'admin'
    },
    {
        name: 'roomsList',
        level: 1,
        order: 4,
        cat: 'admin'
    },
    {
        name: 'operatorsList',
        level: 1,
        order: 5,
        cat: 'admin'
    },
    {
        name: 'interventionsTech',
        level: 1,
        order: 6,
        cat: 'tech'
    },
    {
        name: 'luckyWheel',
        level: 1,
        order: 7,
        cat: 'games'
    }
]