import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, OutlinedInput } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserRoles } from "../../services/roomsProcess";
/*  
    Used on StaffList/newUser (/newuser) to create a new user
    TODO : 
    - check if we can find the user in the AD user list
    - All the "Role" thing is commented waiting to know if we need to change the role
*/

export default function NewUserForm({props}) {
    const { openNewUser, setOpenNewUser, handleCreateUser } = props;
    const { t } = useTranslation()

    const [ rolesList , setRolesList ] = useState([]);
    // const [ role, setRole ] = useState("");
    
    useEffect(() => {
        getUserRoles(setRolesList);
        // setRole(rolesList.length > 0? "visiteur" : '')
    }, [rolesList?.length])
    
    

    const handleClose = () => {
        setOpenNewUser(false);
    }
    return(
        <Dialog open={openNewUser} className="newUserForm" id="newUserForm" component="form" onClose={handleClose} onSubmit={handleCreateUser} fullWidth maxWidth="md">
            <DialogTitle sx={{display:'flex',justifyContent:'space-between'}}>
                {t('newUser')}
                
            </DialogTitle>
            <DialogContent>

            
            <Grid container  spacing={4} onSubmit={handleCreateUser}>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth sx={{mt:2}}>
                        <InputLabel htmlFor="nom">{t('userDatas.lastName')}</InputLabel>
                        <OutlinedInput name="nom" label={t('userDatas.lastName')} required/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth sx={{mt:2}}>
                        <InputLabel htmlFor="prenom">{t('userDatas.firstName')}</InputLabel>
                        <OutlinedInput name="prenom" label={t('userDatas.firstName')} required/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="mail">{t('userDatas.mail')}</InputLabel>
                        <OutlinedInput name="mail" label={t('userDatas.mail')} type="email" required/>
                    </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="">{t('userDatas.role')}</InputLabel>
                        <Select
                            label="selectRole"
                            id="selectRole"
                            name="role"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            
                        >{
                            rolesList.map((role) => {
                                return (
                                    <MenuItem key={`operator_${role.id}`} value={role.name}>{role.name}</MenuItem>
                                )                 
                            })
                        }
                        </Select>
                    </FormControl>
                </Grid> */}
                
                
            </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('cancel')}</Button>
                <Button type="submit" variant="contained" >{t('saveBtn')}</Button>
            </DialogActions>
        </Dialog>
        )
    }
    
    
            