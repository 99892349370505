import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircleIcon from '@mui/icons-material/Circle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LuckyWheelActivationForm from "../components/forms/LuckyWheelActivationForm";
import DatasList from '../components/misc/DatasList';
import { GlobalContext } from '../context/Global';
import { getAllActiveList } from '../services/luckyWheel';


export default function LuckyWheel() {
    const [ openNewActivation, setOpenNewActivation ] = useState(false);
    const [ modaleType, setModaleType ] = useState('new');
    const [ modaleValue, setModaleValue ] = useState({});
    const { t } = useTranslation();
    const { selectedRoom } = useContext(GlobalContext);

    const [ allActive, setAllActive ] = useState([]);
    useEffect(() => {
        if (selectedRoom) {
            getAllActiveList(selectedRoom, setAllActive)
        }
        
    }, [selectedRoom])

    const stateRender = (value) => {
        const start = new Date(value.row.date_debut);
        const end = new Date(value.row.date_fin);
        const today = new Date()

        if (end < today){
            return (<Typography variant="overline" component="p" sx={{display:"flex",alignItems: "center",fontWeight:"bold"}}><CircleIcon sx={{color:"#de3629",mr:2}}/>{t('luckyWheel.past')}</Typography>)
        } else if (start > today) {
            return (<Typography variant="overline" component="p" sx={{display:"flex",alignItems: "center",fontWeight:"bold"}}>{t('luckyWheel.future')}</Typography>)
        } else {
            return (<Typography variant="overline" component="p" sx={{display:"flex",alignItems: "center",fontWeight:"bold"}}><CircleIcon sx={{color:"#25ad25",mr:2}}/>{t('luckyWheel.present')}</Typography>)
        }
    }

    const updateActivation = (event, value) => {
        setModaleType('edit');
        setModaleValue(value.row);
        setOpenNewActivation(true);
    }
    const copyActivation = (event, value) => {
        setModaleType('copy');
        setModaleValue(value.row);
        setOpenNewActivation(true)
    }

    const activationsWheelColumns = [
        { field: 'id', headerName: 'Id', flex: 0.3, type: 'integer', sortComparator: (v1, v2) => parseInt(v1) - parseInt(v2) },
        { field: 'state', headerName: t('luckyWheel.state'), flex: 0.5, renderCell: (value) => stateRender(value)},
        { field: 'config_name', headerName: t('luckyWheel.name'), flex: 1, type: 'string'},
        { field: 'config_num', headerName: t('luckyWheel.config'), flex: 0.5,type: 'integer'},
        { field: 'nb_token', headerName: t('luckyWheel.token'), flex: 0.5, type: 'integer'},
        { field: 'date_debut', headerName: t('luckyWheel.start'), flex: 1, type: 'dateTime', valueGetter: (value) => {return new Date(value.row.date_debut)}},
        { field: 'date_fin', headerName: t('luckyWheel.finish'), flex: 1, type: 'dateTime',valueGetter: (value) => {return new Date(value.row.date_fin)}},
        { field: 'master_serial', headerName: t('luckyWheel.masterSerial'), flex: 1, type: 'string'},
        { field: 'update', headerName: t('update'), flex:0.3, renderCell: (value) => <EditIcon onClick={(event) => updateActivation(event, value)} />, align: 'center' },
        { field: 'copy', headerName: t('copyContent'), flex:0.3, renderCell: (value) => <ContentCopyIcon onClick={(event) => copyActivation(event, value)} />, align: 'center'}
    ]

  return (
    <Container>
        <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'space-between', m:0 }}>
            {t('menu.luckyWheel')}
            <Button variant="contained" onClick={() => {setOpenNewActivation(true);setModaleType('new')}}>{t('luckyWheel.newActivation')}</Button>
        </Typography>
        <LuckyWheelActivationForm props={{openNewActivation, setOpenNewActivation, modaleType, setModaleType, modaleValue, setModaleValue,selectedRoom, setAllActive, allActive}} />
        <Box sx={{display:"flex",justifyContent:"center",p:4,}}>
            <Button href={"/luckyWheel/configurations"} variant="contained" size="small" className="whiteLink" sx={{ml:2,mr:2}} endIcon={<ArrowForwardIcon/>}>{t(`luckyWheel.configurationLink`)}</Button>
            <Button href={"/luckyWheel/results"} variant="contained" size="small" className="whiteLink"sx={{ml:2,mr:2}} endIcon={<ArrowForwardIcon/>} >{t(`luckyWheel.resultsLink`)}</Button>
        </Box>
        {allActive.length ? 
        
        <DatasList 
        completeList={allActive}
        columns={activationsWheelColumns}
         />
         :
         <Typography variant="p">{t('noResults')}</Typography>
        }
    </Container>
    
  )
}

