import SaveIcon from '@mui/icons-material/Save';
import { Autocomplete, Button, Card, CardContent, Grid, List, ListItem, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../../context/Global";
import { UserContext } from "../../context/UserContext";
import { getCompanies, getUserRoles, updateRoomsForUsers } from "../../services/roomsProcess";
import { updateUser } from "../../services/userProcessDB";
import TransferList from "./TransferList";


/*    
    Used on StaffList/user (/user/id) to edit an user. The "id" is the database id, not uuid
    TODO : 
    1. Display rooms only when companies are affected
    2. Make companies editable only by RH group
*/

// Adding the user ID From old DB manually, available only for Admin
// Will display the uuid if it exists, or the field to add it (for admin)
const UuidField = ({props}) => {
    const { t, staffDatas, mobile} = props;
    if(!staffDatas.uuid){
        return(
            <ListItem sx={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                <Typography sx={{width:"20%"}} variant="fieldTitle">{t('userDatas.uuid')} :</Typography>
                <TextField
                        required
                        id="uuidField"
                        label="Required"
                        name="uuidField"
                        size="small"
                        sx={{width:mobile ? "50%" : "77.5%",marginLeft:1}}
                    />
            </ListItem>
        )
    }else{
        return(
        <ListItem sx={{display:"flex",justifyContent:"space-between",width:"100%"}}>
            <Typography variant="fieldTitle" sx={{width:"20%"}}>{t('userDatas.uuid')} :</Typography>
            <Typography variant="body1" sx={{width:mobile ? "50%" : "77.5%"}}>{staffDatas.uuid}</Typography>
        </ListItem>
        )
    }
}
const PhoneNumberField = ({props}) => {
    const { staffDatas, t, mobile } = props;
    return(
        <ListItem sx={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
            <Typography sx={{width:"20%"}} variant="fieldTitle">{t('userDatas.phoneNumber')} :</Typography>
            <TextField
                defaultValue={staffDatas.gsm ? staffDatas.gsm : ""}
                    id="gsmField"
                    label={t('userDatas.phoneNumber')}
                    name="gsmField"
                    size="small"
                    sx={{width:mobile ? "50%" : "77.5%"}}
                />
        </ListItem>
    )
    
}
// Select field which allows to Admin to change the role of an user
const RoleField = ({props}) => {
    const { t, isAdmin, isRH, rolesList, userRole, setUserRole, mobile } = props;
    return (
        <ListItem sx={{display:"flex",justifyContent:"space-between",width:"100%"}}>
            <Typography sx={{width:"20%"}} variant="fieldTitle">{t('userDatas.role')} :</Typography>
            <Select
                labelId="selectRole"
                id="selectRole"
                value={userRole}
                name="selectRole"
                size="small"
                sx={{width:mobile ? "50%" : "77.5%"}}
                onChange={(event) => {setUserRole(event.target.value)}}
            >{
                rolesList.map((role) => {
                    return (
                        ((isAdmin || (isRH && (role.name !== "admin" && role.name !== "techlead" && role.name !== "rh"))) &&
                            <MenuItem key={`role_${role.id}`} value={role.name}>{role.name}</MenuItem>
                        )
                    )                 
                })
            }
            </Select>
        </ListItem>
    )
}
// Field which display company name if it exists, or the select fielt to add it (for admin and RH)
const CompanyField = ({props}) => {
    const {t, companies ,userCompany, setUserCompany, mobile} = props;
    const [value, setValue] = useState(userCompany);
    const [inputValue, setInputValue] = useState(userCompany);
    var newCompagnies = []
    companies.forEach(element => {
        newCompagnies = [...newCompagnies, element.firm]
    });
    return (
        <ListItem sx={{display:"flex",justifyContent:"space-between",width:"100%"}}>
            <Typography sx={{width:"20%"}} variant="fieldTitle">{t('userDatas.company')} :</Typography>
            <Autocomplete
                value={value}
                onChange={(event,newValue) => {setValue(newValue);setUserCompany(event.target.value)}}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {setInputValue(newInputValue)}}
                size="small"
                id="companiesAutocomplete"
                options={newCompagnies}
                
                sx={{ width: mobile ? "50%" : "77.5%" }}
                renderInput={(params) => <TextField {...params} label={t('userDatas.company')} name="company"/>}
                />
            {/* <Button sx={{width:"20%"}} variant="contained" onClick={(event) => {handleCompany({staffDatas,userCompany,event});}}>{mobile ? <SaveIcon /> : t('saveBtn')}</Button> */}
        </ListItem>
    )
}
/* 
    Props : 
    - StaffData = user datas includes : {active, cardN, company, email, id, nom, prenom, role and uuid}
    - allRooms = List of all rooms includes : {id, text (room name)}
    - staffRooms = List of all rooms assigned to user : {id, text (room name)}
*/
export default function EditUserForm({props}) {
    const { staffDatas, allRooms, staffRooms } = props;
    const { isAdmin, isRH } = useContext(UserContext)
    const { t } = useTranslation();

    const [ rolesList, setRolesList ] = useState([]);
    const [ userRole, setUserRole ] = useState(staffDatas.role ? staffDatas.role : '');
    // const [ operatorsList, setOperatorsList ] = useState([]);
    const [ companies, setCompanies ] = useState([]);
    const [ userCompany, setUserCompany ] = useState(staffDatas.company ? staffDatas.company : '');
    const { mobile } = useContext(GlobalContext);
    // create an array with all the room excluding the rooms where the user is affected
    let allRoomsWithoutStaffRooms = allRooms.filter((room) => {
        return !staffRooms.some((el) => JSON.stringify(el) === JSON.stringify(room))
    })

    // Save the rooms for the user
    const handleSubmitRooms = (forwhat, datasToSave) => {
        let salle_list = "";
        datasToSave.map((room) => {
            return salle_list += room.id+','
        })
        updateRoomsForUsers({uuid_personel : forwhat.uuid_personel, salle_list : salle_list.slice(0, -1)})
    }
    const handleUpdateUser = (event) => {
        const form = event.target.form;
        const datas = [{
            company: form.company.value,
            gsm: form.gsmField.value,
            role: form.selectRole.value
        }]
        if (form.uuidField) {
            datas.push({uuid: form.uuidField.value})
        }
        const forWho = (staffDatas.mail ? {mail: staffDatas.mail} : {id: staffDatas.id} );
        updateUser([datas[0],forWho])
    }
    // Enable or disable the user
    const handleActiveUser = (active) => {
        const forWho = (staffDatas.mail ? {mail: staffDatas.mail} : {id: staffDatas.id} );
        updateUser([{active:active},forWho])
    }
    
    useEffect(() => {
        getCompanies(setCompanies)
        getUserRoles(setRolesList)
    },[])
    return(
        <>
            <Card sx={{mb:2,overflow:"visible"}}>
                <CardContent>
                    <Grid container component="form" spacing={4}>
                        <Grid item container xs={12} md={8} direction="column" alignItems="baseline" justifyContent="space-between" className="formUserInfos">
                            <List sx={{width:"100%"}}>
                                <ListItem  sx={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                                    <Typography variant="fieldTitle" sx={{width:mobile ? "50%" : "20%"}} >{t('userDatas.lastName')} :</Typography>
                                    <Typography variant="body1" sx={{width:mobile ? "50%" : "77.5%"}} >{staffDatas.nom}</Typography>
                                </ListItem>
                                <ListItem sx={{display:"flex",justifyContent:"space-between",width:"100%"}}>
                                    <Typography variant="fieldTitle" sx={{width:mobile ? "50%" : "20%"}} >{t('userDatas.firstName')} :</Typography>
                                    <Typography variant="body1" sx={{width:mobile ? "50%" :"77.5%"}} >{staffDatas.prenom}</Typography>
                                </ListItem>
                                {companies.length > 0 &&
                                    <CompanyField props={{t, companies ,userCompany, setUserCompany, mobile}}/>
                                }
                                <PhoneNumberField props={{ staffDatas, t, mobile }} />
                                {isAdmin && 
                                    <UuidField props={{staffDatas, t, mobile}}/>
                                }
                                {((isAdmin || isRH)) && 
                                <>
                                {rolesList.length > 0 &&
                                    <RoleField props={{t, isAdmin, isRH, rolesList, setRolesList, userRole, setUserRole, staffDatas, mobile}}/>
                                }
                                </>
                                }
                            </List>
                        </Grid>
                        <Grid item container xs={12} md={4} direction="column" alignItems={mobile ? "center" : "flex-end"} className="formUserbtns">
                            <Button variant="contained" sx={{mb:2}}>{t('staffManagement.askCard')}</Button>
                            <Button variant="contained" onClick={() => {handleActiveUser((staffDatas.active === 0 ? 1 : 0))}}>{(staffDatas.active === 0 ? t('staffManagement.enableUser') : t('staffManagement.disableUser'))}</Button>
                        </Grid>
                        <Grid item container xs={12} md={12}  direction="row" sx={{alignItems:"flex-end"}} className="GridRight">
                                <Button sx={{marginLeft:"auto"}}  variant="contained" onClick={(event) => handleUpdateUser(event)}>{mobile ? <SaveIcon /> : t('saveBtn')}</Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {staffDatas.uuid && 
                <Grid container spacing={4}>
                    <Grid item xs={12} sx={{my: 2, mx: mobile ? 2 : 0}}>
                        <Typography variant="fieldTitle">{t('userDatas.affectedRooms')}</Typography>
                        <TransferList completeList={allRoomsWithoutStaffRooms} savedDatas={staffRooms} submitFct={handleSubmitRooms} forwhat={{"uuid_personel":staffDatas.uuid}}/>
                    </Grid>
                </Grid>
            }
            {!staffDatas.uuid &&
            <Grid container spacing={4}>
                <Grid item xs={12} container>
                    <Typography variant="p" >{t('userDatas.noUserIdForRooms')}</Typography>
                </Grid>
            </Grid>
            }
        </>
        )
    }
    
    
            