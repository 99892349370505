import MenuIcon from '@mui/icons-material/Menu';
import { Box, Container, IconButton, Toolbar, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/Global';
import { UserContext } from '../../context/UserContext';
import { SignOutButton } from '../SignOutButton';
import MenuDrawer from './Menu';
import RoomChoice from './RoomChoice';


/*
  This is the main header, displayed for each user logged.
  It contains the appBar (with site name, select to change room and signout button) and the menuDrawer
  /!\ the changeRoom is commented because not used yet 
*/
export default function Header() {
  const { t } = useTranslation();
  const { router } = useContext(UserContext)
  const { globalValues, openDrawer, setOpenDrawer, mobile} = useContext(GlobalContext)
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const navigate = useNavigate()
  
  // Open or close the drawer menu
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };
  const appBarWidth = openDrawer && !mobile ? `calc(100% - ${globalValues.drawerWidth}px)` : '100%';
  const appBarmargin = openDrawer && !mobile ? `${globalValues.drawerWidth}px` : '0';

  if(!sessionStorage.getItem('accessOpened')){
      navigate('/logout')
  }
  
  return(
    <>
    <Box sx={{display: "flex"}}>
      <AppBar position="fixed" sx={{width:appBarWidth, marginLeft:appBarmargin}}>
        <Container>
          <Toolbar variant="regular" className="toolbarHead" >
            <Box sx={{display: "flex",alignItems:"center"}}>
            <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(openDrawer && { display: "none" }) }}
            >
              <MenuIcon/>
              
            </IconButton>
            <Typography variant="h1" noWrap component="div">{t('siteName')}</Typography>
            </Box>
            
            {(globalValues.isAuthDB) &&
            (Object.keys(router).includes("changeRoom") && globalValues.isCluster) && <RoomChoice />}
            <SignOutButton />
          </Toolbar>      
        </Container>
        <MenuDrawer drawerWidth={globalValues.drawerWidth} openDrawer={openDrawer} handleDrawerClose={handleDrawerClose} router={router}/>
      </AppBar>
      
      
    </Box>
    
    </>
    
    )
  }
  