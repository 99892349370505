import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format, parseISO } from "date-fns";
import { enGB, fr, nlBE } from 'date-fns/locale';
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { createActive, getAllConfig, updateActive } from "../../services/luckyWheel";


export default function LuckyWheelActivationForm({props}) {
    const { i18n,t } = useTranslation();
    const { openNewActivation, setOpenNewActivation, modaleType, setModaleType, modaleValue, setModaleValue,selectedRoom, setAllActive, allActive } = props;
    const today = new Date()
    const [ config, setConfig] = useState(0);
    const [ startDate, setStartDate] = useState(today);
    const [ endDate, setEndDate] = useState(modaleValue?.date_fin ? parseISO(modaleValue.date_fin) : today);
    const [ allConfig, setAllConfig ] = useState({})

    
    
    useEffect(() => {
        getAllConfig(selectedRoom, setAllConfig)
        if (modaleValue?.date_debut) {setStartDate(parseISO(modaleValue.date_debut))}
        if (modaleValue?.date_fin) {setEndDate(parseISO(modaleValue.date_fin))}
        
    }, [modaleValue.date_debut, modaleValue.date_fin, selectedRoom])
    const firstConfigFind = parseInt(Object.getOwnPropertyNames(allConfig)[0]);
    useEffect(() => {
        setConfig(firstConfigFind)
    },[firstConfigFind])

    
    const handleClose = () => {
        setOpenNewActivation(false);
        setModaleType('new')
        setModaleValue({})
        setStartDate(today)
        setEndDate(today)
        setConfig(firstConfigFind)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const refreshList = (updatedActiv) => {
        if (modaleType === 'new' || modaleType === "copy") {
            setAllActive(((activs) => ([...activs, updatedActiv]).sort(activs.id)))
        } else {
            const newObject = allActive.filter(item => item.id !== updatedActiv.id);
            const sortedList = ([...newObject, updatedActiv]).sort((a, b) => a.id - b.id)
            setAllActive(sortedList)
        }
        handleClose();
    }

    const handleSave = (event) => {
        const form = event.target.form;

        
        const datas = [{
            config_name: form.newActifName.value,
            uuid_salle:selectedRoom,
            config_num:form.newActivConfig.value,
            date_debut:format(new Date(startDate), 'yyyy-MM-dd HH:mm:ss'),
            date_fin:format(new Date(endDate), 'yyyy-MM-dd HH:mm:ss'),
            master_serial:form.newActifMasterSerial.value,
            nb_token:form.newActifToken.value
        }]

        if((datas[0].config_name === "" || !datas[0].config_name) || (datas[0].config_num === "" || !datas[0].config_num)) {
            toast.error(t('luckyWheel.emptyFieldsError'))
        }
        else if (modaleType === "new" || modaleType === "copy") {
            createActive(datas, refreshList)
        } else {
            datas.push({id:modaleValue.id})
            updateActive(datas, refreshList)
        }
        
        
    }
    
    const configList = []
    Object.entries(allConfig).map(a => configList.push(a[0]))
    const locale = i18n.resolvedLanguage === 'fr' ? fr : i18n.resolvedLanguage === 'nl' ? nlBE : enGB;
    return (
        <Dialog open={openNewActivation} onClose={handleClose} fullWidth maxWidth="sm" component="form">
            <DialogTitle>{modaleType === "edit" ? t('luckyWheel.updateActivation') : modaleType === "copy" ? t('luckyWheel.copyActivation') : t('luckyWheel.newActivation')}</DialogTitle>
            <DialogContent>
                <FormControl variant="outlined" fullWidth sx={{mt:3}}>
                    <TextField 
                    id="name" 
                    label={t('luckyWheel.name')} 
                    variant="outlined" 
                    name="newActifName"
                    defaultValue={modaleType === "edit" || modaleType === "copy" ? modaleValue.config_name : ''}
                    required
                    />
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{mt:3}}>
                    <InputLabel id="newActivConfigLabel" >{t('luckyWheel.NewActivationConfig')}</InputLabel>
                    <Select
                    labeld="newActivConfig"
                    defaultValue={modaleType === "edit" || modaleType === "copy" ? parseInt(modaleValue.config_num) : config}
                    value={config}
                    id="newActivConfig"
                    name="newActivConfig"
                    label={t('luckyWheel.NewActivationConfig')}
                    onChange={(event) => setConfig(event.target.value)}
                    required
                    >
                    
                     {configList.map((config) => (
                        <MenuItem value={parseInt(config)} key={`config_${config}`}>
                        <span>{config}</span>
                        </MenuItem>
                    ))} 
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{mt:3}} >
                    <LocalizationProvider
                    adapterLocale={locale}
                    dateAdapter={AdapterDateFns}
                    >
                        <DateTimePicker
                        label={t('luckyWheel.start')}
                        slotProps={{ textField: { variant: "outlined", name:"newActivDateDebut" } }}
                        value={modaleType === "edit" || modaleType === "copy" ? parseISO(modaleValue.date_debut) : startDate}
                        sx={{width:'auto'}}
                        onChange={(value) => setStartDate(value)}
                        required
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{mt:3}}>
                    <LocalizationProvider
                    adapterLocale={locale}
                    dateAdapter={AdapterDateFns}
                    >
                        <DateTimePicker
                        label={t('luckyWheel.finish')}
                        slotProps={{ textField: { variant: "outlined", name:"newActivDateFin" } }}
                        value={modaleType === "edit" || modaleType === "copy" ? parseISO(modaleValue.date_fin) : endDate}              
                        sx={{width:'auto'}}
                        onChange={(value) => setEndDate(value)}
                        required
                        />
                    </LocalizationProvider>
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{mt:3}}>
                    <TextField 
                    id="masterSerial" 
                    label={t('luckyWheel.masterSerial')} 
                    variant="outlined" 
                    name="newActifMasterSerial"
                    defaultValue={modaleType === "edit" || modaleType === "copy" ? modaleValue.master_serial : ''}
                    />
                </FormControl>
                <FormControl variant="outlined" fullWidth sx={{mt:3}}>
                    <TextField 
                    id="newActifToken" 
                    label={t('luckyWheel.token')} 
                    variant="outlined" 
                    name="newActifToken"
                    defaultValue={modaleType === "edit" || modaleType === "copy" ? modaleValue.nb_token : ''}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('cancel')}</Button>
                <Button variant="contained" onClick={handleSave}>{t('saveBtn')}</Button>
            </DialogActions>
        </Dialog>
    )
}
        