import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { GlobalContext } from '../../context/Global';
import { UserContext } from '../../context/UserContext';
/*
  Select displaying in Header to allow some user to choose the room they want to connect to (for Admin, FieldManager, DistrictManager, Techlead, Marketing and Controlling) 

  TODO: Develop this part with the room assigned to the user based on the userContext (need Groups)
*/
export default function RoomChoice() {
  const  { selectedRoom, setSelectedRoom } = useContext(GlobalContext);
  const { userDatas } = useContext(UserContext)
  const navigate = useNavigate();
  const accessRooms = [];
  Object.entries(userDatas.salles).forEach(room => {
    accessRooms.push({id: room[0],name: room[1]})
  })
  const menuItems = accessRooms.map(salle => (
      <MenuItem value={salle.id} key={salle.id}>
          <span>{salle.name}</span>
      </MenuItem>
  ))
  

  const handleChange = (event) => {
    localStorage.setItem('selectedRoom', event.target.value);
    setSelectedRoom(event.target.value);
    navigate('/')
    navigate(0)
  };
  return (
    <FormControl id="formRoomChoice" variant="standard" sx={{ m: 1, minWidth: 120 }}>
      {/* <InputLabel id="roomChoiceLabel">Salle</InputLabel> */}
        <Select
          value={(selectedRoom)}
          id="roomChoice"
          onChange={handleChange}
          // sx={{color: "#e4b95a"}}
        >
          <MenuItem value="" key="cluster">
            <span>Cluster</span>
          </MenuItem>
          {menuItems}
          
        </Select>
      </FormControl>
      
  );
}