import { useIsAuthenticated } from "@azure/msal-react";
import { useMediaQuery, useTheme } from '@mui/material';
import { createContext, useEffect, useState } from "react";

export const GlobalContext = createContext({})

/**
 * Will keep all the global variables used throughout the application
 * Values : 
 * env: define if we are in development or production environment
 * marginMain: define the margin of the main container
 * drawerWidth: define the width of the drawer and needed to set the container and header width
 * isCluster: Needed to know if we need to change room or not and others things linked to Cluster
 * isAuth: Define if the user is logged in or not
 * msalEnabled: Define if the user can be logged in with his Microsoft account
 * selectedRoom: define the selected room if we are in cluster to know what datas to handle
 * openDrawer: Set if drawer is open or not and used to define the header and container width
 * matches: Check if we are on mobile (< md size) or desktop/tablet portrait (> md size)
 * mobile : Check if we are on moble (< sm size)
 */

export const GlobalProvider = ({children}) => {
    const env = process.env.NODE_ENV;
    const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.down('md'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [ selectedRoom, setSelectedRoom ] = useState((localStorage.getItem('selectedRoom') ? localStorage.getItem('selectedRoom') : ''))
    const [ openDrawer, setOpenDrawer ] = useState(mobile ? false : true);
    const [ isAuthDB, setIsAuthDB ] = useState((localStorage.getItem("isAuthDB") ? localStorage.getItem("isAuthDB") : false));
    
    const isAuthMsal = useIsAuthenticated();
    const drawerWidth = 240;
    const marginMain = openDrawer && !mobile ? `${drawerWidth}px` : '0';
    /** TODO :
     * - add an if statement to define : isCluster 
     * - find a way to setup msalEnabled outside to make the app exportable
    */
    const handleWindowResize = (mobile) => {
        if (mobile) {
            setOpenDrawer(false)
        } else { 
            setOpenDrawer(true) 
        }
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowResize(mobile));
    }, [mobile])
    const isCluster = true;
    const msalEnabled = process.env.REACT_APP_MSAL_ENABLED === "true" ? true : false;
    const isAuth = (!msalEnabled ? isAuthDB : isAuthMsal);
    const globalValues = {
        env:env,
        marginMain:marginMain,
        drawerWidth:drawerWidth,
        isCluster:isCluster,
        isAuth:isAuth,
        msalEnabled:msalEnabled,
        isAuthDB:isAuthDB,
        isAuthMsal:isAuthMsal
    }
    return (
        <GlobalContext.Provider
        value={{
            selectedRoom, 
            setSelectedRoom, 
            openDrawer, 
            setOpenDrawer,
            setIsAuthDB,
            globalValues,
            mobile
        }}
        >
            {children}
        </GlobalContext.Provider>
    )
}
