import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../context/Global";
import { UserContext } from "../context/UserContext";
import { HandleSignOutAD } from "../services/userProcessAD";
import { HandleSignOutDB } from "../services/userProcessDB";

export default function Logout() {
    const { setIsAuthDB, globalValues } = useContext(GlobalContext);
    const { instance } = useMsal();
    const { datasFromMS } = useContext(UserContext);
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    console.log('is Authenticated on Microsoft ?'+ isAuthenticated, instance)
    useEffect(() => {
        
        if (isAuthenticated) {
            console.log('Still authenticated on Microsoft')
            HandleSignOutAD(instance, datasFromMS, HandleSignOutDB, setIsAuthDB, navigate)
        } else if (!isAuthenticated && (globalValues.isAuthDB || globalValues.isAuthDB === "true")) {
            console.log('no more authenticated on Microsoft but still on server')
            HandleSignOutDB(setIsAuthDB, navigate);
        } else {
            console.log('totally logged out')
            navigate("/");
        }
    })
   
}
