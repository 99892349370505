import {
	Button,
	Card,
	Container,
	List,
	ListItem,
	Typography, useTheme
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import NewUserForm from '../components/forms/NewUserForm';
import VerifyUserForm from '../components/forms/VerifyUserForm';
import DatasList from '../components/misc/DatasList';
import DeleteConfirmation from '../components/misc/DeleteConfirmation';
import { GlobalContext } from '../context/Global';
import { UserContext } from '../context/UserContext';
import { getOperators, getUserRoles } from '../services/roomsProcess';
import { createUser, deleteUser, getStaffList, recoverUser, updateUser } from '../services/userProcessDB';

/**
* Link : /stafflist
* Available for : managers, techlead, rh and admins
* Description : This page display the list of all users with access to the app.
*
* If we click on a user, we can edit his informations (/user/:id)
*/

const ActiveUsersForms = ({props}) => {
	const { disabledUsers, t, setOpenVerifyForm, setDatasVerifyForm, setOpenDeleteConfirmation, setDatasToDelete } = props;
	return (
		disabledUsers.map((user) => {
			return (
				<ListItem key={user.id} component="form" sx={{display: 'flex', justifyContent: 'space-between', width: '45%'}} >
				<Typography variant="p" sx={{width:'30%'}}>{`${user.prenom} ${user.nom}`}</Typography>
				<Typography variant="p" sx={{width:'35%'}}>{user.mail ? `${user.mail}` : "unknown mail"}</Typography>
				<input type="hidden" name="userId" value={user.id} />
				<Button variant="contained" sx={{width:'15%'}} onClick={(event) => {setOpenVerifyForm(true);setDatasVerifyForm(user)}}>
					{t('verify')}
				</Button>
				 <Button variant="contained" sx={{width:'15%'}} onClick={() =>{ setDatasToDelete([{name: `${user.nom} ${user.prenom}`,value: user.id}]);setOpenDeleteConfirmation(true)}}>
					{t('delete')}
				</Button> 
			</ListItem>
			)
		})
	)
};

export default function StaffList() {
	const { t } = useTranslation();
	const { isAdmin, isTechLead } = useContext(UserContext);
	const { mobile } = useContext(GlobalContext);
	const [ staffList, setStaffList ] = useState([]);
	const [ rolesList, setRolesList ] = useState([]);
	const [ openNewUser, setOpenNewUser ] = useState(false);
	const [ operatorsList, setOperatorsList ] = useState([]);
	const [ openVerifyForm, setOpenVerifyForm ] = useState(false);
	const [ datasVerifyForm, setDatasVerifyForm ] = useState([])
	const [ enabledUsers, setEnabledUsers ] = useState([]);
	const [ disabledUsers, setDisabledUsers ] = useState([]);
	const [ openDeleteConfirmation, setOpenDeleteConfirmation ] = useState(false);
	const [ datasToDelete, setDatasToDelete ] = useState([]);
	const [ userUpdated, setUserUpdated ] = useState([]);

	const theme = useTheme()
	
	const refreshList = () => {
	
		if(userUpdated[0].verified === "1"){		
			setEnabledUsers(((users) => ([...users, userUpdated[0]]).sort(users.id)));
			setDisabledUsers(disabledUsers.filter(item => item.id !== userUpdated[0]?.id))
			
		} else {
			const existingUser = enabledUsers.find((user) => user.id === userUpdated[0].id);
			const newObject = disabledUsers.filter(item => item.id !== userUpdated[0].id);
			setDisabledUsers([...newObject, userUpdated[0]])
			if(existingUser !== undefined) {
				setEnabledUsers(enabledUsers.filter(item => item.id !== userUpdated[0]?.id))
			}
		}
		setUserUpdated([]);		
	}

	useEffect(() => {
		getOperators(setOperatorsList);
		const statement = isTechLead ? { role: 'tech' } : {};
		getStaffList(setStaffList, statement);
		getUserRoles(setRolesList);
	}, [isTechLead]);

	useEffect(() => {
		if(userUpdated.length > 0){
			refreshList(userUpdated[0]);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userUpdated])

	const handleEnableUser = (event, setIsNewUser, resetVerifyForm) => {
		event.preventDefault();
		const uuid = event.target.form.recover.value;
		const role = event.target.form.selectRole.value;
		
		
		if (role.length > 0) {
			
			if (uuid.length > 0 && uuid !== "new") {
				recoverUser([{"uuid":uuid,"role":role},{"id":datasVerifyForm.id}], setUserUpdated, t('staffManagement.toastUpdatedUser'))
				if(userUpdated.length > 0){
					refreshList(userUpdated[0])
				}
				
				setIsNewUser(false);
				resetVerifyForm()
				setOpenVerifyForm(false);
			} else {
				const affectedRooms = event.target.form?.affectedVerifiedRoom?.value	
				if (affectedRooms.length > 0) {
					updateUser([{role: role, verified: 1, active: 1}, {id: datasVerifyForm.id}], setUserUpdated);
					if(userUpdated.length > 0){
						refreshList(userUpdated[0])
					}
					
					setIsNewUser(false);
					resetVerifyForm()
					setOpenVerifyForm(false);
				} else {
					toast.error(t('staffManagement.errorVerifiedRoom'))
				}
			}
		} else {
			toast.error(t('staffManagement.errorVerifiedUuidRole'));
		}
		
	}
	const handleDelete = (event, id) => {
		event.preventDefault();
		deleteUser(id, t('staffManagement.toastDeletedUser')).then((resp) => {
			setDisabledUsers(disabledUsers.filter(item => item.id !== id))
		})
		setOpenDeleteConfirmation(false);
		setDatasToDelete([])
		
		toast.success('user Updated')
	}
	const handleCreateUser = (e) => {
        e.preventDefault();
        const datas = {
            mail: e.target?.mail?.value,
            nom: e.target.nom.value,
            prenom: e.target.prenom.value
        };
        createUser([datas], setUserUpdated, t('staffManagement.toastCreatedUser'))
		setOpenNewUser(false);
		document.getElementById("newUserForm").reset();
    }
	
	// create a new array with only the name of roles and companies to use in filters
	const rolesListNames = rolesList.map((role) => role.name);
	const companyListNames = operatorsList.map((operator) => operator.name);
	// Create the columns to send to DataGrid
	const columns = [
		{ field: 'id', headerName: 'Id', flex: 0.3, type: 'integer' },
		{
			field: 'nom',
			headerName: t('userDatas.lastName'),
			width: mobile ? 150 : 200,
			type: 'string'
		},
		{
			field: 'prenom',
			headerName: t('userDatas.firstName'),
			flex: mobile ? 'none' : 0.3,
			width: mobile ? 150 : 'auto',
			type: 'string'
		},
		{
			field: 'role',
			headerName: t('userDatas.role'),
			flex: mobile ? 'none' : 0.3,
			width: mobile ? 100 : 'auto',
			type: 'singleSelect',
			valueOptions: rolesListNames
		},
		{
			field: 'gsm',
			headerName: t('userDatas.phoneNumber'),
			flex: mobile ? 'none' : 0.3,
			width: mobile ? 200 : 'auto',
			type: 'string'
		},
		{
			field: 'mail',
			headerName: t('userDatas.mail'),
			flex: mobile ? 'none' : 0.3,
			width: mobile ? 200 : 'auto',
			type: 'string'
		},
		{
			field: 'company',
			headerName: t('userDatas.company'),
			flex: 1,
			type: 'singleSelect',
			valueOptions: companyListNames
		}
	];
	
	const visibilityColumns = {
		company: true,
		mail: true
	};
	
	if (isAdmin) {
		columns.push({
			field: 'uuid',
			headerName: t('userDatas.uuid'),
			flex: 1,
			type: 'string'
		});
	}	
	useEffect(() => {
		setEnabledUsers([]);
		setDisabledUsers([]);
		staffList.forEach((user) => {
			if (user.verified === '0') {
				setDisabledUsers((users) => [...users, user]);
			} else {
				setEnabledUsers((users) => [...users, user]);
			}
		}); 
	}, [staffList])

	return (
		<Container>
			<Typography
			variant="h2"
			sx={{ display: 'flex', justifyContent: 'space-between' }}
			>
				{t('menu.staffList')}
				<Button variant="contained" onClick={() => setOpenNewUser(true)}>
				{t('newUser')}
				</Button>
			</Typography>
			
			{disabledUsers.length > 0 && (
				<Card sx={{ p:4, backgroundColor:theme.palette.secondary.light, mb:4}}>
					<Typography variant="h3">{t('staffManagement.verifyListTitle')}</Typography>
					<List className="disabledUserList" sx={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: "space-between" }}>
						<ActiveUsersForms props={{disabledUsers, rolesList, handleEnableUser, t, handleDelete, setOpenVerifyForm, setDatasVerifyForm, setOpenDeleteConfirmation,setDatasToDelete,datasToDelete}} />    
						<VerifyUserForm props={{t, openVerifyForm, datasVerifyForm, handleEnableUser, rolesList, setDatasVerifyForm, setOpenVerifyForm}} fullWidth maxWidth="sm"/>
					</List>
				</Card>
			)}
				<DeleteConfirmation props={{openDeleteConfirmation, handleDelete, setOpenDeleteConfirmation, datasToDelete, setDatasToDelete }}/>
			<NewUserForm props={{ openNewUser, setOpenNewUser, handleCreateUser }}></NewUserForm>
			<DatasList
			completeList={enabledUsers}
			columns={columns}
			linkForNavigation="/user/"
			redirectOn={['nom', 'prenom']}
			linkToId={true}
			copyCell={["gsm", "mail"]}
			visibilityColumns={visibilityColumns}
			legend={t('staffManagement.legend')}
			/>
		</Container>
	);
}
