// import { useContext, useEffect, useState } from "react";
// import { GlobalContext } from "../context/Global";
// import { UserContext } from "../context/UserContext";
// import { useMsal } from "@azure/msal-react";
import { toast } from "react-hot-toast";


const headers = new Headers({
  "User-Agent": "Client Synchro",
  "X-Custom-User-Agent": "Client Synchro v0.1",
  "Content-Type": "application/json;charset=utf-8",
  Authorization: "Basic Q1NMb3VyZDpmZTVjMmVhOTMxODk1MTJlMmY4NDcyMjg5MThlMjcyOWU4OTFmZDk2ZmI5MGI3YzBlMDRmYmFiYmQ3OWZhODMz",
  "Access-User": localStorage.getItem("mail"),
});

/*  Method is the API Method : GetStaffs, SetStaff, ...
    Params is an array of parameters and could have 2 array inside : 
     0 : first array will contain the informations to send to DB ['lang' : 1]
     1 : second array with the "where" statement ['prenom' : '%truc%']
        It could also contain a second array with filters to receive only datas we need : ['nom', 'prenom', 'mail'] (and the API will only send us "prenom", "nom" and "mail")
*/
const fetchDatas = async (method, params = []) => {
  const requestOptions = {
    jsonrpc: "2.0",
    method: "POST",
    withCredentials: true,
    headers: headers,
    body: JSON.stringify({
      id: 1,
      method: `Personnel\\IStaff::${method}`,
      params,
    }),
  };
  const data = 
      await fetch(process.env.REACT_APP_API_ENDPOINT, requestOptions)
      .then((response) => response.json())
      .then((results) => {return results.result})
      // .then((apiDatas) => {return apiDatas})
      .catch((err) => {console.error(err);toast.error(err)});
    return data;
};

// Function who will login the user and check if auth is correct. Will create the Backend session in same time
export const HandleLoginDB = ( datas, setUserDatas, setIsAuthDB, getGrants, setAllGrants ) => {
  const mail = [{"mail" : datas.mail}];
  localStorage.setItem('mail',datas.mail)
  const login = fetchDatas('OpenAccess',mail);
  login
  .then((response) => {
    if(response.verified !== undefined){
      setUserDatas(response);
      setIsAuthDB(true);
      let jsonResponse = JSON.stringify(response);
      localStorage.setItem('userDatas',jsonResponse);
      localStorage.setItem('isAuthDB',true);
      sessionStorage.setItem('accessOpened', true)
    }else if(!response.length){
      const datasToSave = [{
        nom: datas.nom,
        prenom: datas.prenom,
        mail: datas.mail, 
        uuid_AD: datas.uuid_AD,
        lang: datas.lang,
        active: (datas.accountEnabled ? 1 : 0),
        company: datas.companyName
      }]
      fetchDatas('SetStaff',datasToSave);
    }
  }).then(() => {
    getGrants(setAllGrants);
  })
  // const response = fetchDatas("GetStaffs", datas);
  // setIsAuthDB(true);
  // return response;
};

// function which will logout the user (doesn't concern MSAL Login)
export const HandleSignOutDB = (setIsAuthDB, navigate) => {
  console.log('start signout from server/DB')
  const logout = fetchDatas('CloseAccess',[{"mail" : localStorage.getItem('mail')}]);
  setIsAuthDB(false);
  localStorage.setItem('isAuthDB',false);
  localStorage.setItem('loggedOut',true);
  localStorage.removeItem('userDatas');
  localStorage.removeItem('mail');
  localStorage.removeItem('gr');
  localStorage.removeItem('ugr');
  localStorage.removeItem('selectedRoom');
  sessionStorage.removeItem('accessOpened');
  logout
  .then(() => {navigate("/");})
  .catch((err) => console.error(err));
  console.log('logged out from server/DB')
};

// Function to update an user (change password, preferred language, ...)
export const updateUser = (datas, setUserUpdated) => {
  const updateStaff = fetchDatas("UpdateStaff", datas);
  updateStaff.then((response) => {
    if(setUserUpdated !== undefined){
      setUserUpdated(response.staff)
    }
  });
};

// Function to get the user datas we need and if the user doesn't exists in the database, we create it
export const DatasFromDB = async (datas, setUserDatas) => {
  const getStaff = fetchDatas("GetStaffs", [datas[0][0], datas[1]]);
  getStaff.then((response) => {
    if (!response.length) {
      fetchDatas("SetStaff", datas).then(DatasFromDB(datas, setUserDatas));
    } else {
      setUserDatas(response[0]);
      // return response
    }
  });
};

// Function to get the list of all staff managed by the user (if his role fit)
export const getStaffList = (setStaffList, statement = {}) => {
  const getStaff = fetchDatas("GetStaffs", [statement,["id", "nom", "prenom", "role", "mail", "uuid", "company", "active", "verified", "gsm"]]);
  getStaff.then((response) => {
    setStaffList(response);
  });
};

// Function to get the single user data from another user
export const getUserDatas = async (id, setUserDatas) => {
  const getStaff = fetchDatas("GetStaffs", [{ id: id },["id", "cardN", "nom", "prenom", "role", "serial", "uuid", "mail", "active", "company", "gsm"]]);
  getStaff.then((response) => {
    setUserDatas(response[0]);
    return response[0].length;
  });
};

// Function to create a new user from /newUser
export const createUser = async (datas, setUserUpdated, text) => {
  const createUser = fetchDatas("SetStaff", datas);
  createUser.then((resp) => {  
    setUserUpdated(resp.staff);
    return resp.staff
    }).then(() => {toast.success(text);});
}

// Verify if an user is already exists in the old database
export const verifyUser = async (datas, setVerifiedUser) => {
  const verify = fetchDatas("Verify", datas);
  verify.then((response) => {
    setVerifiedUser(response)
    return response;
  })
}

// A user has been verified and exists in old database, so we recover his datas
export const recoverUser = async (datas, setUserUpdated) => {
  const recover = fetchDatas('RecoverStaff', datas);
  recover.then((response) => {
    setUserUpdated(response.staff)
  }).then(() => toast.success("user updated !"))
}

export const deleteUser = async (datas) => {
  const deleteFromDB = fetchDatas('DeleteStaff', [{id:datas}]);
  deleteFromDB.then((response) => {
    return response.staff
  }).then(() => toast.success('User deleted !'));
}