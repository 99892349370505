import { Box } from '@mui/material';
import { React, useCallback, useContext, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import Login from './components/Login';
import Header from './components/misc/Header';
import { GlobalContext } from './context/Global';
import { UserContext } from './context/UserContext';
import ApiTests from './routes/ApiTests';
import AppRights from './routes/AppRights';
import Home from './routes/Home';
import InterventionsTech from './routes/InterventionsTech';
import Logout from './routes/Logout';
import LuckyWheel from './routes/LuckyWheel';
import LuckyWheelConfig from './routes/LuckyWheelConfig';
import LuckyWheelResults from './routes/LuckyWheelResults';
import OperatorsList from './routes/OperatorsList';
import ProfileData from './routes/ProfileData';
import RoomsList from './routes/RoomsList';
import SingleUser from './routes/SingleUser';
import StaffList from './routes/StaffList';

/**
 * Description : Define all the routes for the application depending of the authentication status.
 * Define also the wrapper container for all the application components.
 * 
 * TODO : Change the way ApiTest page is displayed (should not be for "admin")
 */
export const Router = () => {
  
    const { globalValues, openDrawer, mobile } = useContext(GlobalContext);
    const { completeUserAccess, userDatas } = useContext(UserContext)
    const AuthenticatedRouter = () => {
      if (userDatas?.verified === "1") {
        return (
          <>
            <Header/>
            <Toaster />
            <Box className='mainContainer' sx={{
              marginLeft:(!mobile ? globalValues.marginMain : 0), 
              width:(openDrawer && !mobile ? `calc(100% - ${globalValues.drawerWidth}px)` : '100%')
              }}>
              <Routes>
                  <Route path='/' element={<Home/>} />
                  <Route path='/user/:usersId' element={completeUserAccess?.user?.length && completeUserAccess?.staffList?.find((item => item.alias === 'view')) ? <SingleUser/> : <Home />} action={({ params }) => {}} />
                  <Route path='/stafflist' element={completeUserAccess?.staffList?.length && completeUserAccess?.staffList?.find((item => item.alias === 'view')) ? <StaffList/> : <Home />} />
                  <Route path='/profile' element={completeUserAccess?.profile?.length && completeUserAccess?.profile?.find((item => item.alias === 'view')) ? <ProfileData/> : <Home />} />
                  <Route path='/apprights' element={completeUserAccess?.appRights?.length && completeUserAccess?.appRights?.find((item => item.alias === 'view')) ? <AppRights /> : <Home />} />
                  <Route path='/devtool' element={completeUserAccess?.devTool?.length && completeUserAccess?.devTool?.find((item => item.alias === 'view')) ? <ApiTests /> : <Home />} />
                  <Route path='/roomslist' element={completeUserAccess?.roomsList?.length && completeUserAccess?.roomsList?.find((item => item.alias === 'view')) ? <RoomsList /> : <Home />} />
                  <Route path='/operatorslist' element={completeUserAccess?.operatorsList?.length && completeUserAccess?.operatorsList?.find((item => item.alias === 'view')) ? <OperatorsList /> : <Home />} />
                  <Route path='/interventionsTech' element={completeUserAccess?.interventionsTech?.length && completeUserAccess?.interventionsTech?.find((item => item.alias === 'view')) ? <InterventionsTech /> : <Home />} />
                  <Route path='/luckywheel' element={completeUserAccess?.luckyWheel?.length && completeUserAccess?.luckyWheel?.find((item => item.alias === 'view')) ? <LuckyWheel /> : <Home />} />
                  <Route path='/luckywheel/configurations' element={completeUserAccess?.luckyWheel?.length && completeUserAccess?.luckyWheel?.find((item => item.alias === 'view')) ? <LuckyWheelConfig /> : <Home />} />
                  <Route path='/luckywheel/results' element={completeUserAccess?.luckyWheel?.length && completeUserAccess?.luckyWheel?.find((item => item.alias === 'view')) ? <LuckyWheelResults /> : <Home />} />
                  <Route path='/logout' element={<Logout />} />
              </Routes>
            </Box>
          </>
        );
      } else {
        return (
          <>
            <Header />
            <Toaster />
            <Box className='mainContainer' sx={{
                marginLeft:(!mobile ? globalValues.marginMain : 0), 
                width:(openDrawer && !mobile ? `calc(100% - ${globalValues.drawerWidth}px)` : '100%')
                }}>
                  <Routes>
                    <Route path='/' element={<Home/>} />
                    <Route path='/profile' element={completeUserAccess?.profile?.length && completeUserAccess?.profile?.find((item => item.alias === 'view')) ? <ProfileData/> : <Home />} />
                  </Routes>
            </Box>
          </>
        )
      }
      
    }
    const UnAuthenticatedRouter = () => {
      return (
        <Routes>
          <Route path='/' element={<Login/>} />
          <Route path='*' element={<Login/>} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      )
    }
    const isAuthRouter = useCallback(() => {
      if (globalValues.isAuthMsal && globalValues.isAuthDB && Object.keys(completeUserAccess).length) {
        return <AuthenticatedRouter />
      } else {
        return <UnAuthenticatedRouter />
      }
    }, [completeUserAccess, globalValues.isAuthDB, globalValues.isAuthMsal])
   
    useEffect(() => {
      isAuthRouter()
    }, [isAuthRouter])

    /** Msal contains its own auth management and can allow or not a user to access the application
     *  It's why there is a separate component for this purpose, because msal could be unavailable.
     *  The only page a user not logged can access is Login.
     * 
     * TODO: find a way to try with MSAL unavailable 
     */
    return (isAuthRouter())
   
}