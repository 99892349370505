import { toast } from "react-hot-toast";

const headers = new Headers({
    'User-Agent': 'Client Synchro',
    'X-Custom-User-Agent': 'Client Synchro v0.1',
    'Content-Type': 'application/json;charset=utf-8',
    'Authorization': 'Basic Q1NMb3VyZDpmZTVjMmVhOTMxODk1MTJlMmY4NDcyMjg5MThlMjcyOWU4OTFmZDk2ZmI5MGI3YzBlMDRmYmFiYmQ3OWZhODMz',
    "Access-User": localStorage.getItem("mail"),
  })

/**
 * Description : All the requests to the server concerning the rooter
 * 
 * 
 * fetchDatas : Request only with one function called by every function which need it.
 * Parameters : 
 *  - method : the API method to call
 *  - params : array(s) with datas to send or retrieve
 */
const fetchDatas = async (method, params = []) => {
    const requestOptions = {
        jsonrpc:'2.0',
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
        id: 0,
        method: `Personnel\\${method}`,
        params
        })
    };
    const data = await fetch(process.env.REACT_APP_API_ENDPOINT, requestOptions)
    .then((response) => response.json())
    .then((results) => {return results.result})
    .catch((err) => {toast.error(err);});
    // .then((results) => {
    //     apiDatas = results.result; 
    // }).then((apiData) => { return apiDatas})
    return data;
}

// Retrieve the pages the user can access
export const getUserRouter = async (setAllRouter, role) => {
    // const getRouter = fetchDatas('GetAcces', [{'role':role}]);
    // getRouter.then((response) => {
    //     setAllRouter(response);
    // })
}

// Retrive all the router datas and sort them by alphabetical order or by ID
export const getRouterAccess = async (setPageList,mustBeSorted) => {
    const getRouter = fetchDatas('IRoleGrants::GetRouter', []);
    getRouter.then((response) => {
        // let sortedList = [];
        // if(mustBeSorted) {
        //     sortedList = response.sort(function(a, b) {
        //         if (a.page_name < b.page_name) {return -1;}
        //         if (a.page_name > b.page_name) {return 1;}
        //         return 0;
        //     })
        // }else{
        //     sortedList = response.sort(function(a, b) {
        //         if (a.id < b.id) {return -1;}
        //         if (a.id > b.id) {return 1;}
        //         return 0;
        //     })
        // }
        // setPageList(sortedList) 
        setPageList(response)
    })
}

// Send the new router datas to the server
export const updateRouterAccess = async (datas) => {
    const updateRouter = fetchDatas('IRoleGrants::SetGrants', [datas]);
    updateRouter.then((response) => {
        if (response.err === 0) {
            toast.success("Router updated !");
        } else {
            toast.error(response.message)
        }
    })
}

export const getGrants = async (setAllGrants) => {
    const getGrants = fetchDatas('IEditor::Get', [{"table":"grants"}]);
    var allGrants = [];
    getGrants.then((response) => {
        localStorage.setItem('gr',JSON.stringify(response));
        setAllGrants(response);
        allGrants = response;
        return response;
    });
    return allGrants;
}

export const getPagesList = async (setPagesList) => {
    const getList = fetchDatas('IEditor::Get', [{"table":"pages"}]);
    getList.then((response) => {
        setPagesList(response)
    })
}

export const setNewPage = async (datas) => {
    const setNewPage = fetchDatas('IEditor::Create', [{"table":"pages"}, datas]);
    setNewPage.then((response) => {
        console.log(response);
    })
}