import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

export default function DeleteConfirmation({props}) {
  const { t } = useTranslation();
	const { openDeleteConfirmation, handleDelete, setOpenDeleteConfirmation, datasToDelete, setDatasToDelete } = props;

  const handleClose = () => {
    setOpenDeleteConfirmation(false);
    setDatasToDelete([])
  }

  const name = datasToDelete[0]?.name;
  const value = datasToDelete[0]?.value;
	return (
		<Dialog
        open={openDeleteConfirmation}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('deleteTitleConfirmation')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Trans i18nKey='deleteTextConfirmation' values={{datasToDelete: name}} components={{bold: <strong />}} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirmation(false)}>{t('cancel')}</Button>
          <Button variant="contained" onClick={(event) => handleDelete(event, value)} autoFocus>{t('delete')}</Button>
        </DialogActions>
      </Dialog>
	)
}