import { toast } from 'react-hot-toast';

const headers = new Headers({
	'User-Agent': 'Client Synchro',
	'X-Custom-User-Agent': 'Client Synchro v0.1',
	'Content-Type': 'application/json;charset=utf-8',
	Authorization:
		'Basic Q1NMb3VyZDpmZTVjMmVhOTMxODk1MTJlMmY4NDcyMjg5MThlMjcyOWU4OTFmZDk2ZmI5MGI3YzBlMDRmYmFiYmQ3OWZhODMz',
	'Access-User': localStorage.getItem('mail')
});

/**
 * Description : All the requests to the server concerning the Tech pages
 * 
 * 
 * fetchDatas : Request only with one function called by every function which need it.
 * Parameters : 
 *  - method : the API method to call
 *  - params : array(s) with datas to send or retrieve
 */
const fetchDatas = async (method, params = []) => {
    const requestOptions = {
        jsonrpc:'2.0',
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
        id: 0,
        method: `Personnel\\${method}`,
        params
        })
    };
    const data = await fetch(process.env.REACT_APP_API_ENDPOINT, requestOptions)
    .then((response) => response.json())
    .then((results) => {return results.result})
    .catch((err) => {toast.error(err);});
    return data;
}

export const getAlltechInterventions = async (day, setAllInterventions) => {
    const getInterventions = fetchDatas('ITech::GetLogin', [{"day":day}]);
    getInterventions.then((response) => {
        setAllInterventions(response);
        if (response.length > 0) {
            toast.success("Updated list");
        } else {
            toast("No datas found");
        }
    })
}