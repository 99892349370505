import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import mainTheme from './context/Theme';
import { CssBaseline } from '@mui/material';
import { CookiesProvider } from 'react-cookie';
import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './authConfig';
import App from './App'
import './i18n'
// import { createSession } from './services/userProcessDB';

// eslint-disable-next-line
// const callbackLogin = msalInstance.addEventCallback((message) => {
//   if (message.eventType === "msal:loginSuccess") {
//     localStorage.setItem('mail',message.payload.account.username);
//     createSession()
//   }
// });
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
        <CssBaseline />
        <ThemeProvider theme={mainTheme}>
          <CookiesProvider>
            <App />
          </CookiesProvider>
        </ThemeProvider>
    </MsalProvider>
  </React.StrictMode>
  );
  