export const decomposeBinaryNb = (nb) => {
    let values = [];
    let power = 0;

    while (nb > 0) {
      if (nb % 2 === 1) {
        values.push(Math.pow(2, power));
      }
      nb = Math.floor(nb / 2);
      power++;
    }
    return values;
  }

export const formatDateFromString = (date) => {
  const { format } = require("date-fns");
  const newDate = format(new Date(date), "dd-MM-yyyy HH:mm:ss")
  return newDate
}