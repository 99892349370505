import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Button /*, Divider*/, Drawer, IconButton, MenuItem, MenuList, styled } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../context/Global';
import { UserContext } from '../../context/UserContext';
import { pagesList } from '../../datas/pagesList';

// Main menu contained in the drawer
// TODO: Make the Citech link dynamic and not hardcoded

const DrawerHeader = styled('div')(({ mainTheme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

const DrawerFooter = styled('div')(({ mainTheme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: "1em",
  marginTop: "auto",
  justifyContent: 'center',
}))
 
/*
  Props : 
  - drawerWidth: The width of the drawer set in header
  - openDrawer: state of drawer (true or false)
  - handleDrawerClose: function to close the drawer
  - router: Based on DB app_router table and set on src/Router.jsx
*/
export default function MenuDrawer({drawerWidth, openDrawer, handleDrawerClose}) {
  const { t } = useTranslation();
  const { mobile } = useContext(GlobalContext);
  const { completeUserAccess, userDatas } = useContext(UserContext);
  const menuItems = [{link: "/", translation:"home"},];

  if (userDatas?.verified === "1") {
    for(let page in completeUserAccess){
      pagesList.filter((el) => {
        if(page === el.name && el.level === 1){
          menuItems.push({
            link: `/${page.toLowerCase()}`, 
            translation: page, 
            order: el.order,
            // authorized:(router.filter())
          })
        }
        return menuItems
      })
    }
  } else {
    menuItems.push({
      link: '/profile',
      translation: 'profile',
      order: 1
    })
  }
  

  return (
      <Drawer
      sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={mobile ? "temporary" : "persistent"}
        anchor="left"
        open={openDrawer}
    >
      <DrawerHeader>
        
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      <MenuList>
      { menuItems.sort((a, b) => a.order - b.order).map((el, index) => (
      <MenuItem 
      key={`menu_key${index}`}>
        <Link to={el.link}>{t(`menu.${el.translation}`)}</Link>
      </MenuItem>     
    ))}
      </MenuList>
      <DrawerFooter>
        <Button 
        size="small" 
        variant="contained" 
        href="https://www.citech.be/"
        sx={{background:'#e4b95a',textAlign:"center",
        '&hover': {
          color:"#FFF",
        }}}
        >{t('menu.oldVersionButton')}</Button>
      </DrawerFooter>
    </Drawer>
  )
}