import SaveIcon from '@mui/icons-material/Save';
import { Button, Card, CardContent, Container, Grid, List, ListItem, TextField, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PersonalUser from "../components/forms/PersonalUser";
import { GlobalContext } from "../context/Global";
import { UserContext } from "../context/UserContext";
import { updateUser } from '../services/userProcessDB';


/** 
 * Link : /profile
 * Available for : everyone
 * Description : This page displays the information of the current user and allow them to change their password and the prefered language.
 */

const ListItems = ({t, userDatas, setUserDatas}) => {
    const { mobile } = useContext(GlobalContext)
    const changeGSM = (event) => {
        //TODO : Include new phone number in LocalStorage
        event.preventDefault();
        var newUserDatas = JSON.parse(localStorage.getItem("userDatas"))
        newUserDatas.gsm = event.target.gsm.value
        setUserDatas(newUserDatas);
        localStorage.setItem("userDatas", JSON.stringify(newUserDatas))
        updateUser([{gsm:event.target.gsm.value},{mail:userDatas.mail}])
    }
    return (
        <List sx={{width:"75%"}}>
            <ListItem>
                <Typography><strong>{t('userDatas.lastName')}</strong> : {userDatas.nom}</Typography>
            </ListItem>
            <ListItem>
                <Typography><strong>{t('userDatas.firstName')}</strong> : {userDatas.prenom}</Typography>
            </ListItem>
            <ListItem>
                <Typography><strong>{t('userDatas.affectedRooms')}</strong> : {userDatas.officeLocation}</Typography>
            </ListItem>
            <ListItem>
                <Typography><strong>{t('userDatas.company')}</strong> : {userDatas.company}</Typography>
            </ListItem>
            <ListItem sx={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%"}} component="form" onSubmit={changeGSM}>
                <Typography sx={{width:"20%"}} variant="fieldTitle">{t('userDatas.phoneNumber')} :</Typography>
                <TextField
                    defaultValue={userDatas.gsm ? userDatas.gsm : ""}
                    id="gsm"
                    label="Gsm"
                    name="gsm"
                    size="small"
                    sx={{width:mobile ? "50%" : "77.5%",marginLeft:1}}
                />
                <Button type="submit" variant="contained" sx={{width:mobile ? "50%" : "22.5%",marginLeft:1}}>{mobile ? <SaveIcon /> : t('saveBtn')}</Button>
            </ListItem>
        </List>
    )
}

export default function ProfileData() {
    const {userDatas, setUserDatas } = useContext(UserContext);
    const { mobile } = useContext(GlobalContext);
    const { t } = useTranslation();
    
    return (
        <Container id="profile-div">
            <Typography variant="h2">{t('profile.title')}</Typography>
            <Card>
                <CardContent>
                <Grid container direction={(mobile ? "column" : "row")} alignItems="baseline" justifyContent="space-between">
                    <Grid item container xs={8} direction="column" alignItems="baseline" justifyContent="space-between">
                        {userDatas && 
                            <ListItems t={t} userDatas={userDatas} setUserDatas={setUserDatas} />}
                    </Grid>
                    <PersonalUser />
                </Grid>
                    
                </CardContent>
            </Card>
            
        </Container>
    );
};