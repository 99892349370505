import { createTheme } from '@mui/material/styles';
import "../style/style.scss";

// see https://material-ui.com/customization/themes/

/**
 * define the theme : 
 * - Colors
 * - Typography : font-size, font-family, font-weight
 * - Display of several components
 */
let theme = createTheme({
  palette: {
    primary: {
      main: '#3f3f3f',
    },
    secondary: {
      main: '#e4b95a',
    },
    error: {
      main: '#de3629',
    },
    text: {
      light: "#FFF",
      dark: "#3f3f3f"
    }
  },
  spacing:5
});
const mainTheme = createTheme(theme, {
  
  typography: {
    fontWeightLight: 300,
    subtitle1: {
      fontSize: '1em',
      fontFamily: "'Open Sans', arial, sans-serif",
    },
    h1: {
      fontSize:'2rem',
      fontFamily: "'Raleway SemiBold', arial, sans-serif",
      fontWeight:700,
      [theme.breakpoints.down('md')]: {
        fontSize:'1rem',
      }
    },
    h2: {
      fontFamily: "'Raleway SemiBold', arial, sans-serif",
      fontSize:'1.3rem',
      margin:'0 .5rem 2rem 0',
      fontWeight:700,
      [theme.breakpoints.down('md')]: {
        marginTop: '.8rem',
        // color:theme.palette.secondary.main,
        textAlign:'center',
        // display:'flex',
      },
      [theme.breakpoints.down('sm')]: {
        display:'flex',
        flexDirection: 'column',
        alignItems: 'center',
        button: {
          marginTop: '1rem',
        }
      }
    },
    h3: {
      fontSize:'1.1rem',
      margin:'1em 0',
      fontFamily: "'Raleway', arial, sans-serif",
    },
    h4: {
      fontFamily: "'Raleway', arial, sans-serif",
    },
    subtitle2: {
      fontFamily: "'Open Sans', arial, sans-serif",
    },
    body1: {
      fontFamily: "'Open Sans', arial, sans-serif",
    },
    body2: {
      fontFamily: "'Open Sans', arial, sans-serif",
    },
    button: {
      fontFamily: "'Raleway SemiBold', arial, sans-serif",
      fontWeight: 700,
    },
    caption: {
      fontFamily: "'Open Sans', arial, sans-serif",
    },
    overline: {
      fontFamily: "'Open Sans', arial, sans-serif",
    },
    fieldTitle: {
      fontFamily: "'Open Sans', arial, sans-serif",
      marginRight: ".5em",
    },
    fontFamily: "'Open Sans', arial, sans-serif",
  },
  direction: "ltr", 
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthLg: {
          [theme.breakpoints.up('lg')]: {
            maxWidth: "1600px"
          },
          [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
            padding:0
          }
        },
        root: {          
          a: {
            color:theme.palette.secondary.main,
            '&.signoutButton':{color:"#FFF"},
            '&.MuiButton-contained':{color:"#FFF"}
          },
          '&.loginBox': {
            width:"40%",
            height:"100vh",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            [theme.breakpoints.down('md')]: {
              width:"100%",
            },
            [theme.breakpoints.up('md')]: {
              width:"60%",
            },
            [theme.breakpoints.up('lg')]: {
              width:"40%",
            }
          }
        }
      }
    },
    MuiTableContainer: {
      styleOverrides:{
        root:{
          '&.tableContainerGridResp':{
            maxHeight:'75vh',
            marginBottom:5,
            [theme.breakpoints.down('sm')]: {
              maxHeight:'60vh'
            }
          },
          '& .tableCategoryName':{
            textAlign:'left',
            borderTop:"none",
            // paddingBottom:theme.spacing(1),
            // paddingTop:theme.spacing(6),
            
            backgroundColor:'rgba(0,0,0,0.04)',
            textTransform:'uppercase',
            '& .MuiTableCell-root:first-of-type': {
              fontWeight:"bold",
            }
          },
          
        },
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor:theme.palette.primary.main,
          button: {
            color: theme.palette.secondary.main,
          },
          a: {
            color: theme.palette.text.light,
            width: "100%",
            "&:hover": {
              color: theme.palette.secondary.main
            }
          },          
        }
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          [theme.breakpoints.up('lg')]: {
            padding: 0,
          },
          display: "flex",
          justifyContent: "space-between",
          "& #roomChoice": {
            backgroundColor: theme.palette.text.light,
            paddingLeft: '12px'
          },
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        contained: { color:theme.palette.text.light},
        outlined: { color:theme.palette.secondary.main },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.secondary.main
          }
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
              fontWeight: 600,
        },
        body:{
          svg:{
            color: theme.palette.secondary.main
          },
          button:{
            svg:{
              color: theme.palette.text.light
            }
          },
          '&.tableCategoryName':{
            textAlign:'left',
            borderTop:"none",
            // paddingBottom:theme.spacing(1),
            // paddingTop:theme.spacing(6),
            fontWeight:"bold",
            backgroundColor:'rgba(0,0,0,0.04)',
            textTransform:'uppercase',
          },
          // CHECK IT LATER
          checkbox: {
            root: {
              svg: {
                color: theme.palette.primary.main
              },
              "&:hover, &:checked": {
                svg: {
                  color: theme.palette.secondary.main
                }
              }
            }
            
          }
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          fieldTitle: "strong"
        }
      },
      styleOverrides:{
        root:{
          '&.titleDbBtn':{
            display:"flex",
            [theme.breakpoints.down('sm')]: {
              flexWrap:'wrap',
              h2:{
                width:"100%"
              }
            }
          },
          '&.titleDbBtn button:nth-last-of-type(2)':{
            marginLeft:"auto",
            display:"block",
            marginRight:12,
            [theme.breakpoints.down('sm')]: {
              marginLeft:0
            }
          }
        }
      }
      

    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border:"none",
          // '&.MuiDataGrid-root .MuiDataGrid-toolbarContainer': {borderBottom: `1px solid ${theme.palette.secondary.main}`},
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-columnHeaderTitleContainer':{justifyContent: 'space-between'},
          
          '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus,&.MuiDataGrid-root .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderDraggableContainer:focus': {
            outline: 'none',
          }, 
          '&.MuiDataGrid-root .MuiDataGrid-row:hover': {
              cursor: 'pointer',
          },
          '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
            fontFamily: "'Raleway Bold', arial, sans-serif",
            fontWeight:700
          },
        },
        panel: {      
          [theme.breakpoints.down('sm')]: {
            width:'100%',
            '&.MuiDataGrid-panel .MuiDataGrid-filterFormDeleteIcon': {
              alignItems: 'flex-end'
            },
            
            '&.MuiDataGrid-panel .MuiFormControl-root': {
              width: '100%'
            }
          },
          
        },
        filterForm: {
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'column',
            }
        },
        
      },
    },
    MuiBadge:{
      styleOverrides: {
        badge: {
          backgroundColor: theme.palette.secondary.main,
        }
      }
      
    },
  },
});

export default mainTheme;