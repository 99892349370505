import { Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import EditUserForm from "../components/forms/EditUserForm";
import { getAllRooms, getUserRooms } from "../services/roomsProcess";
import { getUserDatas } from "../services/userProcessDB";
// import UserInfos from "../datas/userInfos";

/**
 * Link : /user/:id (access via /staffList => Click on a user)
 * Available for : managers, techlead, rh and admins.
 * Description : This page is used to edit a user.
 */

export default function SingleUser() {
    const [ staffDatas, setStaffDatas] = useState([]);
    const [ allRooms, setAllRooms ] = useState([]);
    const [ staffRooms, setStaffRooms] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();

    useEffect(() => {
        if(!params.usersId){
            navigate('/staffList',{replace: true})
            setStaffDatas([]);
            setStaffRooms([])
        }else{
            getUserDatas(params.usersId,setStaffDatas).then(() => {
                if(staffDatas.uuid){
                    getAllRooms(setAllRooms);
                    getUserRooms(staffDatas?.uuid,setStaffRooms)
                }
            });
        }        
    },[params.usersId,staffDatas?.uuid,navigate])  

    // If the user exists, we display his informations. If not, we display a message "doesn't exist"
    if(staffDatas.length !== 0){
        return (
            <Container id="user-div">
                <Typography variant="h2">{`${staffDatas?.nom} ${staffDatas?.prenom}`}</Typography>
                <EditUserForm props={{staffDatas, allRooms, staffRooms}}/>
            </Container>
        );   
    }else{
        return (
        <Container id="user-div">
            <Typography component="p" sx={{m:5}} align="center">
                {t('profile.doesntExists')}
                <Link to="/staffList" className="textLink">{t('menu.staffManagement')}</Link>
            </Typography>
        </Container>
        )
        
    }
}
    