import { toast } from "react-hot-toast";

// import axios from "axios"
const headers = new Headers({
  'User-Agent': 'Client Synchro',
  'X-Custom-User-Agent': 'Client Synchro v0.1',
  'Content-Type': 'application/json;charset=utf-8',
  'Authorization': 'Basic Q1NMb3VyZDpmZTVjMmVhOTMxODk1MTJlMmY4NDcyMjg5MThlMjcyOWU4OTFmZDk2ZmI5MGI3YzBlMDRmYmFiYmQ3OWZhODMz',
  "Access-User": localStorage.getItem("mail"),
})

/**
 * Description : All the requests to the server concerning the rooms, groups, roles and operators
 * 
 * 
 * fetchDatas : Request only with one function called by every function which need it.
 * Parameters : 
 *  - method : the API method to call
 *  - params : array(s) with datas to send or retrieve
 */
const fetchDatas = async (method, params = []) => {
    let apiDatas = [];
    const requestOptions = {
        jsonrpc:'2.0',
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
        id: 0,
        method: `Personnel\\${method}`,
        params
        })
    };
    const data = await (
        await fetch(process.env.REACT_APP_API_ENDPOINT, requestOptions)
    ).json().then((results) => {
        apiDatas = results.result; 
        return apiDatas
    })
    .catch((err) => toast.error(err))
    return data;
    // const datas = axios.post(process.env.REACT_APP_API_ENDPOINT, {
    //     id:0,method:`Personnel\\IStaff::${method}`,
    //     params
    //   }, headers)
    //   .then((response) => {return response.data.result})
    //   .catch((error) => {console.error(error)})
    //   return datas
}

// Retrieve all the rooms stored in the database and create an array with id and name
export const getAllRooms = async (setAllRooms) => {

    const getRooms = fetchDatas('IEditor::Get',[{"table":"salles"}]);
    getRooms.then((response) => {
        const allRooms = [];
        response.map(room => (allRooms.push({"id": room.uuid,"text":room.name})))
        setAllRooms(allRooms);
    })
}


// Get all the rooms for roomList
export const getRoomsList = async (setAllRooms) => {
    const getRooms = fetchDatas('IEditor::Get', [{'table':'salles'}]);
    getRooms.then((response) => {
        const allRooms = [];
        response.map(room => (allRooms.push(
            {
                id: room.sid,
                name: room.name,
                isOn: room.isOn,
                typeSalle: room.typeSalle,
                zoneSalle: room.zone_salle,
                uuid: room.uuid,
                hall: room.hall,
                prefixBank: room.prefixBankPay,
                system: room.system,
                zoneTech: room.zone_tech,
                hid: room.hid,
                operator: room.uuid_operator
            })))
        setAllRooms(allRooms) 
    })
}

export const getZoneRoomsList = async (setZoneRoomsList) => {
    const getZoneRoomsList = fetchDatas('IEditor::Get', [{"table":"salles_zone"}]);
    getZoneRoomsList.then((response) => {
        setZoneRoomsList(response);
    })
}

export const getZoneTechList = async (setZoneTechList) => {
    const getZoneTechList = fetchDatas('IEditor::Get', [{"table":"tech_zone"}]);
    getZoneTechList.then((response) => {
        setZoneTechList(response);
    })
}

export const getTypeRoomsList = async (setTypeRoomList) => {
    const getTypeRoomsList = fetchDatas('IEditor::Get', [{"table":"salles_type"}]);
    getTypeRoomsList.then((response) => {
        setTypeRoomList(response);
    })
}

// Get room datas from its sID
export const getRoomDatas = async (roomId, setRoomDatas) => {
    const getRoomDatas = fetchDatas('IEditor::Get', [{'table':'salles'}, {'din':'2023-09-01 00:00:00'}]);
    getRoomDatas.then((response) => {
        setRoomDatas(response);
        return response
    })
}
// Retrieve all the groups stored in the database and create an array with id and name
// Groups are the links between rooms and users
export const getUserRooms = async (id, setUserRooms) => {     
    const getUserRooms = fetchDatas('IStaff::GetSalles',[{'uuid_personel':id}]);
    getUserRooms.then((response) => {
        const userRooms = [];
        const allRooms = response[0]
        Object.entries(allRooms).forEach((room) => (userRooms.push({"id": room[0], "text": room[1]})));
        setUserRooms(userRooms);
    }).catch((err) => {toast.error(err)})
}

// Send the rooms saved for a user
export const updateRoomsForUsers = async(datas) => {
    const addUserGroups = fetchDatas('IStaff::EditSalles',[datas]);
    addUserGroups.then((response) => {
        toast.success("Rooms updated for this user!");
        // console.log(response);
    })
}

// Retrieve all the roles stored in the database
export const getUserRoles = async(setRolesList) => {
    const getUserRoles = fetchDatas('IEditor::Get',[{"table":"roles"}])
    getUserRoles.then((response) => {
        setRolesList(response);
    })
}

// Retrieve all the operators stored in the database
export const getOperators = async(setOperatorsList) => {
    const getOperators = fetchDatas('IEditor::Get',[{'table':'operator'}]);
    getOperators.then((response) => {
        setOperatorsList(response);
    })
}

export const getCompanies = async(setCompanies) => {
    const getOperators = fetchDatas('IEditor::Get',[{'table':'company'}]);
    getOperators.then((response) => {
        setCompanies(response);
    })
}

