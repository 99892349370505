import { Tab, Tabs, Typography } from "@mui/material"
import { Container } from "@mui/system"
import { DataGrid, enUS, frFR, nlNL } from "@mui/x-data-grid"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { GlobalContext } from "../context/Global"
import { getAllConfig } from "../services/luckyWheel"


export default function LuckyWheelConfig() {
    const { i18n, t } = useTranslation()
    const locale =
		i18n.resolvedLanguage === 'fr'
			? frFR
			: i18n.resolvedLanguage === 'nl'
			? nlNL
			: enUS;
    const [ tab, setTab ] = useState(0);
    const [ allConfig, setAllConfig ] = useState([]); 

    const { selectedRoom } = useContext(GlobalContext);

    useEffect(() => {
        if (selectedRoom) {
            getAllConfig(selectedRoom, setAllConfig)
        }
        
    }, [selectedRoom])
    
    const configColumns = [ 
        { field: 'id', headerName: 'Id', flex: 0.3, type: 'integer'},
        { field: 'case_num', headerName: t('luckyWheel.configCaseNum'), flex: 0.3, type: 'integer'},
        { field: 'case_text', headerName: t('luckyWheel.configText'), flex: 1,type: 'string'},
        { field: 'case_img', headerName: t('luckyWheel.configImg'), flex: 1, type: 'string', renderCell: (params) => <img src={`data:image/png;base64, ${params.formattedValue}`} alt="icon" style={{maxWidth:"50px", maxHeight:'50px'}}/>},
        { field: 'case_value', headerName: t('luckyWheel.configValue'), flex: 1, type: 'string'},
        { field: 'validity', headerName: t('luckyWheel.configValidity'), flex: 1, type: 'string'},
        { field: 'case_print', headerName: t('luckyWheel.configPrint'), flex: 1, type: 'boolean', valueGetter: (params) => parseInt(params.row.case_print)},
    ]

    
    const handleChangeTab = (event, newValue) => {
        setTab(newValue)
    }
    return (
        <Container>
            <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'space-between', m:0 }}>
            {t('luckyWheel.configTitle')}
            </Typography>
            <Tabs value={tab} onChange={handleChangeTab} aria-label="configurations-tab-index" >
                {Object.entries(allConfig).map((config, index) => {
                    return (
                        <Tab 
                        value={index} 
                        label={`Config ${config[0]}`} 
                        id={`tab_${index}`} 
                        key={`tab_${index}`} 
                        aria-controls={`tab_box_${index}`} 
                        />
                    )
                })}
            </Tabs>
            {
                Object.entries(allConfig).map((config, index) => {
                    return ( 
                        <div 
                            role="tabpanel" 
                            style={{ height: "100%", width: "100%"}} 
                            hidden={tab!==index} 
                            id={`tab_box_${index}`} 
                            key={`tab_box_${index}`} >
                        {tab === index ?
                            
                                    <DataGrid 
                                    localeText={locale.components.MuiDataGrid.defaultProps.localeText} 
                                    hideFooterPagination={true}
                                    key={`tab_box_dataGrid_${index}`} 
                                    rows={config[1]} 
                                    columns={configColumns} 
                                    getRowId={row => row?.case_num} 
                                    />
                           
                         : ""}
                        </div>
                        
                )
                })}
           
        </Container>
        )
    }
