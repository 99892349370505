import WarningIcon from '@mui/icons-material/Warning';
import { Container, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../context/UserContext';
/**
 * Link : /
 * Available for : everyone
 * Descriptions : main page with some informations relatives to the application
 * 
 * Home page, where the users are redirected when they :
 * - login
 * - try to access a page that they are not allowed to access
 * - Simply click on the link in the navigation
 * 
 * TODO: 
 * - Develop all the page with : 
 * - statistics
 * - importants messages
 * - release notes
 * - other ? 
 */
export default function Home() {
    const { i18n, t } = useTranslation();
    const { userDatas } = useContext(UserContext);
    const theme = useTheme(); 
    const [ verifiedUser, setVerifiedUser ] = useState(false);

    useEffect(() => {
        if (userDatas?.verified?.length > 0 && userDatas?.verified === "1") {
            setVerifiedUser(true)
        } 
        
    }, [userDatas])

    if (process.env.NODE_ENV === "production") {
        
        return (
            <Container id="homepage">
                <Typography variant='body1'>{t('homepageWelcome')}</Typography>
                {!verifiedUser && 
                <Typography variant="body1" mt={4} sx={{fontWeight:"bold", display:"flex",alignItems:"center"}}><WarningIcon sx={{mr:2,color:theme.palette.secondary.main}}/>{t('homepageUnverifiedUser')}</Typography>}
            </Container>
        )
    } else {
        return (
            <Container id="user-div">
                <Typography variant="h2">Home</Typography>
                <Typography variant='body1'>{t('homepageWelcome')}</Typography>
                {!verifiedUser && 
                <Typography variant="body1" mt={4} sx={{fontWeight:"bold", display:"flex",alignItems:"center"}}><WarningIcon sx={{mr:2,color:theme.palette.secondary.main}}/>{t('homepageUnverifiedUser')}</Typography>}

                <Typography variant="h3">Variables d'environnement</Typography>
                <p>Environement : {process.env.NODE_ENV}</p>

                <p>Base url : {process.env.REACT_APP_BASE_URL}</p>
                <p>App Name : {process.env.REACT_APP_NAME}</p>
                <p>Site title : {process.env.REACT_APP_SITE_TITLE}</p>
                <p>Msal Enabled : {process.env.REACT_APP_MSAL_ENABLED}</p>
                <p>API Endpoint : {process.env.REACT_APP_API_ENDPOINT}</p>
                <p>OLD API Endpoint : {process.env.REACT_APP_API_ENDPOINT_OLD}</p>
                <p>Langue : {i18n.language}</p>
            </Container>
        )
    }
}
    
