import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from "react-i18next";

export const NewGrantForm = ({props}) => {
    const { t } = useTranslation();
    const { openNewGrant, setOpenNewGrant, pagesForGrants, routerPagesForGrants } = props;
    const [ pageValue, setPageValue ] = useState('');
    const [ isSubPage, setIsSubPage ] = useState('');

    const handleClose = () => {
        setOpenNewGrant(false);
    }

    const handleSave = () => {
        setOpenNewGrant(false);
    }

    const handleChangePageValue = (value) => {
        setPageValue(value.target.value)
    }

    const handleChangeSubmenuValue = (value) => {
        setIsSubPage(value.target.value)
    }

    const grantsPagesItems = pagesForGrants.map(page => (
        // console.log(page)
        <MenuItem value={page} key={`page_${page}`}>
            <span>{page}</span>
        </MenuItem>
    ))

    const routerPagesItems = routerPagesForGrants.map(page => (
        <MenuItem value={page} key={`router_page_${page}`}>
            <span>{page}</span>
        </MenuItem>
    ))
    return (
        <Dialog open={openNewGrant} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>{t('appRights.newGrant')}</DialogTitle>
            <DialogContent>
                <FormControl variant="outlined" fullWidth sx={{marginTop:1}}>
                    <InputLabel id="pageSelect" >{t('appRights.pageNameField')}</InputLabel>
                    <Select
                        labeld="pageSelect"
                        value={pageValue}
                        id="pageSelect"
                        label={t('appRights.pageSelect')}
                        onChange={handleChangePageValue}
                    >
                        <MenuItem value="new" key={`page_new`}>
                            <span>{t('appRights.newPageForGrants')}</span>
                        </MenuItem>
                        {grantsPagesItems}
                    </Select>
            </FormControl>
            {pageValue === "new" &&

            // TODO: Find a way to display this block better (styling)
            <Stack direction="row" divider={<Divider orientation='vertical'/>} spacing={2} sx={{mt:2, px:2, pb:2}} justifyContent="space-between">
                <FormControl variant="outlined" sx={{width:"50%"}}>
                    <TextField 
                        id="newPageNameForGrants"
                        label={t('appRights.newPageNameField')}
                        type="text"
                        variant="outlined"
                        />
                </FormControl>
                <FormControl variant="outlined" sx={{width:"50%"}} >
                    <InputLabel id="subPageSelect" >{t('appRights.subPageSelect')}</InputLabel>
                    <Select
                        labeld="subPageSelect"
                        value={isSubPage}
                        id="subPageSelect"
                        label={t('appRights.subPageSelect')}
                        onChange={handleChangeSubmenuValue}
                    >
                        <MenuItem value="no_parent" key={`no_parent`}>
                            <span>{t('appRights.noSubPage')}</span>
                        </MenuItem>
                        {routerPagesItems}
                    </Select>
                </FormControl>
            </Stack>
            }
            <FormControl variant="outlined" fullWidth sx={{marginTop:1}}>
            <TextField 
                margin="dense"
                id="aliasForGrants"
                label="alias"
                type="text"
                fullWidth
                variant="outlined"
                />
            </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('cancel')}</Button>
                <Button variant="contained" onClick={handleSave}>{t('saveBtn')}</Button>
            </DialogActions>
        </Dialog>
    )
}