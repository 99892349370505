import { React } from 'react';
import { BrowserRouter } from 'react-router-dom'
import { UserContextProvider } from './context/UserContext';
import { GlobalProvider  } from './context/Global';
import { Router } from './Router';
// import { msalInstance } from '.';
// import useCookies from 'react-cookie/cjs/useCookies';

export default function App() {
  // const host = window.location.host;
  // const env = "production" 
  // const [loginCookies, setLoginCookie] = useCookies(); 

  return(
    <div className={process.env.REACT_APP_NAME}>
      <GlobalProvider>
        <UserContextProvider>
          <BrowserRouter basename="/">
              <Router />
          </BrowserRouter>
        </UserContextProvider>
      </GlobalProvider>
    </div>
  )
}
  
