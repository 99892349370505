import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Select, Switch, TextField } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";


export const NewRoomForm = ({props}) => {
    const { t } = useTranslation();
    const { openNewRoom, setOpenNewRoom, operatorsList, typeRoomList, zoneRoomsList, zoneTechList } = props;
    const [ roomOperator, setRoomOperator ] = useState('')
    const [ roomType, setRoomType ] = useState('')
    const [ zoneTech, setZoneTech ] = useState('')
    const [ zoneRoom, setZoneRoom ] = useState('')

    const handleClose = () => {
        setOpenNewRoom(false);
    }

    const handleSave = () => {
        setOpenNewRoom(false);
    }
    // Operators
    const operatorsItems = operatorsList.map(operator => (
        <MenuItem value={operator.uuid} key={`operator_${operator.name}`}><span>{operator.name}</span></MenuItem>
    ))
    const handleChangeOperator = (value) => {
        setRoomOperator(value.target.value)
    }

    // TypeRoom 
    const typesRoomItems = typeRoomList.map(type => (
        <MenuItem value={type.typeCode} key={`typeRoom${type.designation}`}><span>{type.designation}</span></MenuItem>
    ))
    const handleChangeType = (value) => {
        setRoomType(value.target.value)
    }

    // ZoneTech
    const zoneTechItems = zoneTechList.map(zone => (
        <MenuItem value={zone.id} key={`zoneTech${zone.nom}`}><span>{zone.nom}</span></MenuItem>
    ))
    const handleChangeZoneTech = (value) => {
        setZoneTech(value.target.value)
    }

    // ZoneSalle
    const zoneRoomsItems = zoneRoomsList.map(zone => (
        <MenuItem value={zone.id} key={`zoneTech${zone.nom}`}><span>{zone.nom}</span></MenuItem>
    ))
    const handleChangeZoneRoom = (value) => {
        setZoneRoom(value.target.value)
    }

    return (
        <Dialog open={openNewRoom} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle sx={{display:'flex',justifyContent:'space-between'}}>
                {t('rooms.newRoom')}
                <FormGroup>
                    <FormControlLabel label={t('rooms.isOn')} labelPlacement="start" control={<Switch aria-label={t('rooms.isOn')} defaultChecked color="secondary" name="roomIsOn"/>} />
                </FormGroup>
                
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={4} sx={{mt:1}}>
                    <Grid item md={4} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                id="roomName"
                                label={t('rooms.roomName')}
                                type="text"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                id="roomHall"
                                label={t('rooms.roomHall')}
                                type="text"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="roomOperator">{t('rooms.operator')}</InputLabel>
                            <Select
                            labelid="roomOperator"
                            value={roomOperator}
                            id="roomOperator"
                            label={t('rooms.operator')}
                            onChange={handleChangeOperator}
                            >
                                {operatorsItems}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                id="roomUuid"
                                label={t('rooms.roomUuid')}
                                type="text"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                id="roomHid"
                                label={t('rooms.roomHid')}
                                type="number"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                id="roomSystem"
                                label={t('rooms.roomSystem')}
                                type="number"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={2} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                id="prefixBank"
                                label={t('rooms.prefixBank')}
                                type="text"
                                variant="outlined"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="roomType">{t('rooms.roomType')}</InputLabel>
                            <Select
                            labelid="roomType"
                            value={roomType}
                            id="roomType"
                            label={t('rooms.roomType')}
                            onChange={handleChangeType}
                            >
                                {typesRoomItems}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="zoneTech">{t('rooms.zoneTech')}</InputLabel>
                            <Select
                            labelid="zoneTech"
                            value={zoneTech}
                            id="zoneTech"
                            label={t('rooms.zoneTech')}
                            onChange={handleChangeZoneTech}
                            >
                                {zoneTechItems}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="zoneRoom">{t('rooms.zoneRoom')}</InputLabel>
                            <Select
                            labelid="zoneRoom"
                            value={zoneRoom}
                            id="zoneRoom"
                            label={t('rooms.zoneRoom')}
                            onChange={handleChangeZoneRoom}
                            >
                                {zoneRoomsItems}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* <Stack direction="row" spacing={2} sx={{mt:2}} justifyContent="space-between"> */}
                    
                    
                    
                {/* </Stack> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('cancel')}</Button>
                <Button variant="contained" onClick={handleSave}>{t('saveBtn')}</Button>
            </DialogActions>
        </Dialog>
    )
}