import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/UserContext";
import { lg } from "../../datas/lang";
import { updateUser } from "../../services/userProcessDB";

// Used to choose the language and displayed in Profile and Login page
// The languages used are saved in datas/lang.js

export const SelectLang = ({from}) => {
    const { t, i18n } = useTranslation();
    const isProfile = (from === "profile");
    const { userDatas } = useContext(UserContext)

    // Change the app's language and send the information to DB to keep it for future use
    const handleChangeLg = (value) => {
        i18n.changeLanguage(value.target.value)
        const currentLg = (lg.find((lang) => lang.value === value.target.value )).id;
        if (isProfile) {
            updateUser([{lang: currentLg},{mail:userDatas.mail}])
        }
    }
    const menuItems = lg.map(lang => (
        <MenuItem value={lang.value} key={`lang_${lang.id}`}>
            {(isProfile ? 
                <span>{lang.name}</span>
                : 
                <strong style={{margin:"0 16px",textTransform:"uppercase"}}>{(isProfile ? lang.name : lang.short)}</strong>
            )}
            
        </MenuItem>
    ))
    // Change the design depending of the page (Login or Profile)
    const variant = (from === "profile" ? "outlined" : "standard");
    const selectWidth = (from === "profile" ? "100%" : "auto")
    return(
    <FormControl variant={variant} sx={{m: 1, marginTop:1, backgroundColor:"#FFF", width:selectWidth}} className="langSelect">
        {from === "profile" && 
            <InputLabel id="lgChoiceLabel">{t('profile.changeLg')}</InputLabel>
        }
        <Select
            labelId="lgChoiceLabel"
            value={i18n.language}
            id="lgChoice"
            label={(from === "profile" ? t('profile.changeLg') : "")}
            onChange={handleChangeLg}
        >
            {menuItems}
            
        </Select>
    </FormControl>
)}