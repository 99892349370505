import { Button, Container, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NewOperatorForm } from "../components/forms/NewOperatorForm";
import DatasList from "../components/misc/DatasList";
import { GlobalContext } from "../context/Global";
import { getOperators } from "../services/roomsProcess";

// TODO : Check if needed to be updated

export default function OperatorsList() {
    const { t } = useTranslation();
    const { mobile } = useContext(GlobalContext);
    const [ operatorsList, setOperatorsList ] = useState([]);
    const [ openNewOperator, setOpenNewOperator ] = useState(false)

    useEffect(() => {
        getOperators(setOperatorsList);
    }, [])
    const columns = [
        {field: 'id', headerName: 'Id', width:50, type: 'integer'},
        {field: 'name', headerName: t('name'), width:mobile ? 200 : 250, type: 'string'},
        {field: 'idSociety', headerName: t('operators.idSociety'), width:mobile ? 150 : 200, type: 'integer'},
        {field: 'IBAN', headerName: 'Iban', width:mobile ? 200 : 250, type: 'string'},
        {field: 'BIC', headerName: 'Bic', width:mobile ? 200 : 250, type: 'string'},
        {field: 'uuid', headerName: 'Uuid', width:320, type: 'string'},
    ]

    return (
        <Container>
            <Typography variant="h2" sx={{ display: "flex", justifyContent: "space-between"}}>
                {t('menu.operatorsList')}
                <Button variant="contained" onClick={() => setOpenNewOperator(true)}>{t('operators.newOperator')}</Button>
            </Typography>
            <NewOperatorForm props={{openNewOperator, setOpenNewOperator}} />
            <DatasList 
                completeList={operatorsList}
                columns={columns}
                linkForNavigation={null}
            /> 
        </Container>
    )
}