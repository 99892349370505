import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export const NewOperatorForm = ({props}) => {
    const { openNewOperator, setOpenNewOperator } = props;
    const { t } = useTranslation();

    const handleClose = () => {
        setOpenNewOperator(false);
    }

    const handleSave = () => {
        setOpenNewOperator(false);
    }

    return (
        <Dialog open={openNewOperator} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle sx={{display:'flex',justifyContent:'space-between'}}>
                {t('operators.newOperator')}
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={4} sx={{mt:1}}>
                    <Grid item md={12} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                id="operatorName"
                                label={t('operators.operatorName')}
                                type="text"
                                variant="outlined"                          
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                id="idSociety"
                                label={t('operators.idSociety')}
                                type="text"
                                variant="outlined"                          
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                id="operatorName"
                                label="uuid"
                                type="text"
                                variant="outlined"                          
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                id="iban"
                                label="Iban"
                                type="text"
                                variant="outlined"                          
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <FormControl variant="outlined" fullWidth>
                            <TextField 
                                id="bic"
                                label="Bic"
                                type="text"
                                variant="outlined"                          
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>{t('cancel')}</Button>
                <Button variant="contained" onClick={handleSave}>{t('saveBtn')}</Button>
            </DialogActions>
        </Dialog>
    )
}