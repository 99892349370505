import {
	Box,
	Button,
	Checkbox,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Typography
} from '@mui/material';
import { useEffect, useState } from 'react';
import { getRoomsList } from '../../services/roomsProcess';
import { verifyUser } from '../../services/userProcessDB';
export default function VerifyUserForm({ props }) {
	const {
		t,
		openVerifyForm,
		datasVerifyForm,
		handleEnableUser,
        rolesList,
		setDatasVerifyForm,
		setOpenVerifyForm
	} = props;
	const [ loading, setLoading ] = useState(false);
	const [ success, setSuccess ] = useState(false);
	const [ verifiedUser, setVerifiedUser ] = useState([]);
    const [ selectValue, setSelectValue ] = useState('')
	const [ isNewUser, setIsNewUser ] = useState(false);
	const [ affectedRooms, setAffectedRoms ] = useState([]);
	const [ allRooms, setAllRooms ] = useState([])
	const [ uuidValue, setUuidValue ] = useState('');
	useEffect(() => {
		if (openVerifyForm) {
			setLoading(true);
			verifyUser([{ id: datasVerifyForm.id }], setVerifiedUser).then((resp) => {
				setSuccess(true);
				setLoading(false);
			});
			getRoomsList(setAllRooms)
		}
	}, [datasVerifyForm.id, openVerifyForm]);

	const handleCloseDialog = () => {
		setOpenVerifyForm(false);
		resetVerifyForm();
		setDatasVerifyForm([])
	}
	const resetVerifyForm = () => {
		setSelectValue('')
		setUuidValue('');
		setAffectedRoms([]);
	}

	const handleRooms = (event) => {
		const { target: {value}} = event;
		setAffectedRoms(typeof value === "string" ? value.split(',') : value);
	}

	return (
		<Dialog
			open={openVerifyForm}
			onClose={() => {handleCloseDialog(); setIsNewUser(false);}}
			fullWidth
			maxWidth="sm"
		>
            <form className="recoverForm" id="recoverForm">
			<DialogTitle>{`${t('verify')} : ${datasVerifyForm.prenom} ${
				datasVerifyForm.nom
			}`}</DialogTitle>
			<DialogContent>
				{loading && (
					<CircularProgress
						size={68}
						// sx={{color}}
					/>
				)}
				{success && verifiedUser && (
					<RadioGroup
                        name="recover"
						onChange={(e) => {setIsNewUser(e.target.value === "new" ? true : false);setUuidValue(e.target.value)}}
						value={uuidValue}
                    >
						{verifiedUser.length >= 1 &&
							verifiedUser.map((user, idx) => {
								return (
									<FormControlLabel
                                        key={idx+1}
										value={user.uuid}
										control={<Radio />}
										label={`${user.login} uuid : ${user.uuid}`}
									/>
								);
							})}
						{!verifiedUser.length && <p>User not found</p>}
						<FormControlLabel
                            key="0"
							value="new"
							control={<Radio />}
							label="New user"
						/>
					</RadioGroup>
				)}
                
				<FormControl required sx={{width:'100%', display:'flex', justifyContent: 'space-between', flexDirection:'row', alignItems: 'center'}}>
				<Typography sx={{width:"30%"}} variant="fieldTitle">{t('userDatas.role')} * :</Typography>
					<Select
						key={`selectRole`}
						labelId={`selectRole`}
						name={`selectRole`}
						size="small"
						variant='outlined'
						value={selectValue}
						sx={{width:"70%"}}
						onChange={(e) => setSelectValue(e.target.value)}
					>
					{rolesList.map((role) => {
						return (
							<MenuItem key={`role_${role.id}`} value={role.name}>
							{role.name}
							</MenuItem>
						);
					})}
					</Select>
				</FormControl>
				{isNewUser && 
				<FormControl required sx={{width:'100%', display:'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center', mt:4}}>
					<Typography sx={{width:"30%"}} variant="fieldTitle">{t('userDatas.affectedRooms')} * :</Typography>
					<Select 
					labelId="affectedVerifiedRoom"
					id="affectedVerifiedRoom"
					name="affectedVerifiedRoom"
					multiple
					value={affectedRooms}
					// input={<OutlinedInput label="rooms" />}
					onChange={handleRooms}
					renderValue={(selected) => (
						<Box sx={{display:'flex', flexWrap: 'wrap', gap: 0.5}}>
							{selected.map((id) => (<Chip key={`chips_${id}`} label={allRooms.find((room) => room.uuid === id).name} />))}
						</Box>
					)}
					MenuProps={{PaperProps: {
						style: {
							maxHeight: 200,
							width: 200
						}
					}}}
					sx={{width:"70%"}}
					>
						{allRooms.map(room => {
							return (
								<MenuItem key={`verified_room_${room.uuid}`} value={room.uuid}>
									<Checkbox checked={affectedRooms.indexOf(room.uuid) > -1} />
									{room.name}
								</MenuItem>
							)
						})}

					</Select>
				</FormControl>
				}
				
			</DialogContent>
			<DialogActions>
				<Button onClick={() => {
					handleCloseDialog(); 
					setIsNewUser(false);
					}}>{t('cancel')}</Button>
				<Button variant="contained" onClick={(event) => {handleEnableUser(event, setIsNewUser, resetVerifyForm);}}>
					{t('saveBtn')}
				</Button>
			</DialogActions>
            </form>
		</Dialog>
	);
}
