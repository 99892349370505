import { Typography } from "@mui/material"
import { Container } from "@mui/system"
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import DatasList from "../components/misc/DatasList"
import { GlobalContext } from "../context/Global"
import { getAllResults } from "../services/luckyWheel"

export default function LuckyWheelResults() {
    const { t } = useTranslation()
    const { selectedRoom } = useContext(GlobalContext);

    const [ allResults, setAllResults ] = useState([]);
    
    useEffect(() => {
        if (selectedRoom) {
          getAllResults(selectedRoom, setAllResults)
        }
        
    }, [selectedRoom])
    const resultsWheelColumns = [
        { field: 'id', headerName: 'Id', flex: 0.3, type: 'integer' },
        { field: 'date_spin', headerName: t('luckyWheel.spinDate'), flex: 1, type: 'dateTime', valueGetter: (value) => {return new Date(value.row.date_spin)}},
        { field: 'active_num', headerName: t('luckyWheel.activation'), flex: 1,type: 'string'},
        { field: 'case_num', headerName: t('luckyWheel.configCaseNum'), flex: 0.3, type: 'integer' },
        { field: 'case_text', headerName: t('luckyWheel.configText'), flex: 1,type: 'string'},
        { field: 'serial', headerName: t('luckyWheel.serial'), flex: 1, type: 'string'},
    ]

  return (
    <Container>
        <Typography variant="h2" sx={{ display: 'flex', justifyContent: 'space-between' }}>
        {t(`luckyWheel.resultsTitle`)}
        </Typography>
        {allResults.length ? 
        <DatasList
        completeList={allResults}
        columns={resultsWheelColumns}
         />
         : 
         <Typography variant="p">{t('noResults')}</Typography>
        }
    </Container>
  )
}
